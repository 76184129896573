export const Twitch = ({ style }: { style?: {} }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={style}
      d="M9.75 1.5H2.25C2.05109 1.5 1.86032 1.57902 1.71967 1.71967C1.57902 1.86032 1.5 2.05109 1.5 2.25V9C1.5 9.19891 1.57902 9.38968 1.71967 9.53033C1.86032 9.67098 2.05109 9.75 2.25 9.75H3V11.25C2.9998 11.321 3.02002 11.3906 3.05825 11.4504C3.09648 11.5103 3.15111 11.5578 3.21562 11.5875C3.26458 11.6137 3.31952 11.6266 3.375 11.625C3.46303 11.6263 3.54834 11.5945 3.61406 11.5359L5.76094 9.75H7.73906C7.91391 9.75044 8.08328 9.689 8.21719 9.57656L10.2281 7.89844C10.3131 7.82936 10.3817 7.74217 10.4287 7.64324C10.4757 7.5443 10.5001 7.43611 10.5 7.32656V2.25C10.5 2.05109 10.421 1.86032 10.2803 1.71967C10.1397 1.57902 9.94891 1.5 9.75 1.5V1.5ZM6 6.375C6 6.47446 5.96049 6.56984 5.89016 6.64017C5.81984 6.71049 5.72446 6.75 5.625 6.75C5.52554 6.75 5.43016 6.71049 5.35984 6.64017C5.28951 6.56984 5.25 6.47446 5.25 6.375V4.125C5.25 4.02554 5.28951 3.93016 5.35984 3.85984C5.43016 3.78951 5.52554 3.75 5.625 3.75C5.72446 3.75 5.81984 3.78951 5.89016 3.85984C5.96049 3.93016 6 4.02554 6 4.125V6.375ZM8.25 6.375C8.25 6.47446 8.21049 6.56984 8.14017 6.64017C8.06984 6.71049 7.97446 6.75 7.875 6.75C7.77554 6.75 7.68016 6.71049 7.60983 6.64017C7.53951 6.56984 7.5 6.47446 7.5 6.375V4.125C7.5 4.02554 7.53951 3.93016 7.60983 3.85984C7.68016 3.78951 7.77554 3.75 7.875 3.75C7.97446 3.75 8.06984 3.78951 8.14017 3.85984C8.21049 3.93016 8.25 4.02554 8.25 4.125V6.375Z"
      fill="#002D2B"
    />
  </svg>
);
