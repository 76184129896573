import { db, storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import {
  collection,
  limit,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { pickBy } from "lodash";
import { getEncodedString } from "../utils/search";

const influencerCollection = collection(db, "influencer");

const sanitizeData = (data: any) => {
  const sanitizedData = {
    name: data.name as string,
    profilePhotoUrl: data.profilePhotoUrl as string,
    categories: data.categories as Array<string>,
    aboutMe: data.aboutMe as string,
    memo: data.memo as string,
  };
  return pickBy(sanitizedData, (v) => v !== undefined);
};

export const fetchInfluencerInfo = async (data: any) => {
  if (!data.uid && !data.handle) {
    throw new Error("No uid or handle provided");
  }

  let influencerQ;
  if (data.uid) {
    influencerQ = query(
      influencerCollection,
      where("uid", "==", data.uid),
      limit(1)
    );
  } else {
    influencerQ = query(
      influencerCollection,
      where("handle", "==", data.handle),
      limit(1)
    );
  }

  const influencerResult = await getDocs(influencerQ);

  if (influencerResult.empty) {
    throw new Error("Failed to fetch influencer info");
  }

  const influencerInfo = influencerResult.docs[0];
  const influencerData = influencerInfo.data();
  const influencerId = influencerInfo.id;
  influencerData["id"] = influencerId;
  if (!influencerData.socialMediaLinks) {
    influencerData["socialMediaLinks"] = {};
  }
  if (!influencerData.categories) {
    influencerData["categories"] = [];
  }
  if (!influencerData.handle) {
    influencerData["handle"] = "";
  }
  if (!influencerData.name) {
    influencerData["name"] = "";
  }
  if (!influencerData.profilePhotoUrl) {
    influencerData["profilePhotoUrl"] = "";
  }
  if (!influencerData.memo) {
    influencerData["memo"] = "";
  }
  if (!influencerData.aboutMe) {
    influencerData["aboutMe"] = "";
  }
  if (!influencerData.theme) {
    influencerData["theme"] = {};
  } else {
    const themeRef = await getDoc(influencerData.theme);
    const themeData = themeRef.data();
    delete influencerData.theme;
    influencerData.theme = themeData;
  }
  return influencerData;
};

export const updateInfluencerBio = async (data: any) => {
  const influencerDocRef = doc(db, "influencer", data.influencerId);
  const sanitizedData = sanitizeData(data);

  await updateDoc(influencerDocRef, sanitizedData);

  const influencerDoc = await getDoc(influencerDocRef);
  return { influencer: influencerDoc.data() };
};

export const checkFollowStatus = async (data: any) => {
  const influencerHandle = data.handle.trim();
  const userId = data.userId.trim();

  const docRef = doc(db, "user", userId);
  const userRef = await getDoc(docRef);

  if (!userRef.exists) {
    throw new Error("User data not found");
  }
  const userData = userRef.data();

  if (!userData) {
    throw new Error("User data not found");
  }

  const influencerQ = query(
    influencerCollection,
    where("handle", "==", influencerHandle),
    limit(1)
  );

  const influencerResult = await getDocs(influencerQ);

  if (influencerResult.empty) {
    throw new Error("Influencer not found");
  }
  const influencerId = influencerResult.docs[0].id;

  for (const influencer of userData.following) {
    if (influencer.id === influencerId) {
      return { following: true };
    }
  }
  return { following: false };
};

export const saveProfilePhoto = async (data: any) => {
  const userId = data.userId;
  const blob = data.blob;

  const mimeType = data.fileType;
  const ext = mimeType === "image/png" ? "png" : "jpg";

  const filePath = `profilePhotos/influencer/${userId}/profile_photo.${ext}`;
  console.log(userId);
  const storageRef = ref(storage, filePath);
  uploadBytes(storageRef, blob).then((snapshot) => {
    console.log("Uploaded a blob or file!");
  });

  const downloadURL = await getDownloadURL(storageRef);

  // console.log(downloadURL)
  const influencerDocRef = doc(db, "influencer", userId);

  await updateDoc(influencerDocRef, { profilePhotoUrl: downloadURL });
  console.log(downloadURL);
  return { success: true };
};

export const searchInfluencer = async (fuzzyString: string) => {
  const influencerIndexCollection = collection(db, "influencerIndex");
  const encodedString = getEncodedString(fuzzyString);
  const influencerQ = query(
    influencerIndexCollection,
    where("tags", "array-contains", encodedString),
    limit(5)
  );

  const influencerResult = await getDocs(influencerQ);

  if (influencerResult.empty) {
    return [];
  } else {
    const influencerValues = [];
    for (const doc of influencerResult.docs) {
      const data = doc.data();
      influencerValues.push({
        name: data.name,
        handle: data.handle,
        categories: data?.categories || [],
        profilePhotoUrl: data?.profilePhotoUrl || null,
      });
    }
    return influencerValues;
  }
};

export const fetchAllInfluencers = async () => {
  const influencerResult = await getDocs(influencerCollection);

  if (influencerResult.empty) {
    return { influencers: [] };
  }

  const influencersArray = influencerResult.docs.map((docData) => {
    const jsonData = docData.data();
    return { id: docData.id, ...jsonData };
  });

  return { influencers: influencersArray };
};
