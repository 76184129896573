import newLogo from "../assets/img/newLogo.png";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar__logo">
        <img src={newLogo} alt="Logo" />
      </div>
      {/* <>
          <div>
            <a
              href="https://www.domeasolid.co"
              target="_blank"
              className="help_link"
              rel="noreferrer"
            >
              Need Help?
            </a>
          </div>
        </> */}
    </div>
  );
};

export default Navbar;
