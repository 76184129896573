export const APP_NAME = "Do Me a Solid";
export const DOMAIN = "mg.domeasolid.co";
export const DMS_APP_USER = "dmas_app_user";
export const DMS_APP_USER_EMAIL = "dmas_app_user_email";
export const DMS_APP_USER_TYPE = "dmas_app_user_type";
export const DMS_APP_THEMES = "dmas_app_themes";
export const DMS_APP_CREATORS_FEED = "dmas_app_creators_feed";
export const DMS_APP_INFLUENCERS = "dmas_app_influencers";
export const DMS_APP_CREATOR_REF = "dmas_app_creator";

export const DMS_EXT_URL =
  "https://chrome.google.com/webstore/detail/do-me-a-solid-extension/kffkknkklgbpahppejnhgbmdkegmpbmf?hl=en-US";

export const MIXP_APP_REGISTER = "APP_REGISTER";
export const MIXP_APP_LOGIN = "APP_LOGIN";
export const MIXP_APP_ADD_CATEGORIES = "APP_ADD_CATEGORIES";
export const SENDER_EMAIL = "hello@domeasolid.co";

export const baseURL = "https://api.domeasolid.app";

export enum EmailTemplate {
  welcome = "welcome",
}

export enum SocialMedia {
  youtube = "youtube",
  instagram = "instagram",
  tiktok = "tiktok",
  discord = "discord",
  twitter = "twitter",
  facebook = "facebook",
  twitch = "twitch",
  other = "other",
}
