import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import SignInPage from "./pages/auth/sign-in";
import SuccessPage from "./pages/auth/success";
import Complete from "./pages/auth/complete";
import { Toaster } from "react-hot-toast";
import { ProtectedRoute } from "./pages/auth/protected-route";
import Extension from "./pages/auth/extension";
import Navbar from "./components/navbar";
import Footer from "./components/footer";

const App = () => {
  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_ID || "");
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route path="/creator/:handle" element={<SuccessPage />} />
        <Route path="/extension/:handle" element={<Extension />} />
        <Route
          path="/complete"
          element={
            <ProtectedRoute>
              <Complete />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<SignInPage />} />
      </Routes>
      <Toaster
        position="bottom-left"
        toastOptions={{
          style: {
            color: "fff",
            zIndex: 1,
          },
          duration: 5000,
          success: {
            duration: 3000,
            style: {
              background: "#DCF8E7",
              border: "0.8px solid #279058",
              borderRadius: "8px",
              color: "#279058",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
            },
          },
          error: {
            duration: 3000,
            style: {
              background: "#FFE4E4",
              border: "0.8px solid #B44445",
              borderRadius: "8px",
              color: "#B44445",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
            },
          },
          loading: {
            duration: 9000,
            style: {
              background: "#FAFAFA",
              border: "0.8px solid #BDBDBD",
              borderRadius: "8px",
              color: "#828282",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontSize: "14px",
            },
          },
        }}
      />
      <Footer />
    </React.Fragment>
  );
};

export default App;
