import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../utils/constants";

interface AuthContextProperties {
  credential?: string | null;
  handleLogin: (token: string, refId: string | null) => Promise<void>;
}

const AuthContext = createContext<AuthContextProperties | null>(null);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [credential, setCredential] = useState<string | null>(null);

  useEffect(() => {
    async function verifySession() {
      let pathname = location.pathname;

      try {
        const response = await fetch(`${baseURL}/auth/session`, {
          credentials: "include",
        });

        if (response.ok) {
          // @Todo(Kodegrenade): work on using a generic auth value from the backend
          setCredential("logged_in");

          if (pathname === "/") {
            pathname = "/complete";
          }

          navigate(pathname);
        }
      } catch (error) {
        console.error("Failed to fetch session:", error);
      }
    }

    verifySession();
  }, [location.pathname, navigate]);

  async function handleLogin(idToken: string, refId: string | null) {
    try {
      const response = await fetch(`${baseURL}/auth/signin`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ idToken, referrer: refId }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        // @Todo: work on using a generic auth value from the backend
        setCredential("logged_in");
        navigate("/complete");
      } else {
        console.error("Login failed with status:", response.status);
      }
    } catch (error) {
      console.error("Login request failed:", error);
    }
  }

  const value: AuthContextProperties = {
    credential: credential,
    handleLogin,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}
