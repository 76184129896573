import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import toast from "react-hot-toast";
import { useAuth } from "../../providers/auth";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import PageTitle from "../../components/page-title";
import FormInput from "../../components/form-input";
import Button from "../../components/button";

import {
  numberFormValidation,
  formatUsNumber,
  getCookie,
} from "../../utils/functions";

import NoCodeModal from "../../components/modal/no-code-modal";
import AlertModal from "../../components/modal/alert-modal";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { auth } from "../../firebase";

import Bowser from "bowser";
import { DMS_APP_CREATOR_REF } from "../../utils/constants";
import { sendEvent } from "../../api";
import OtherSignInMethods from "./otherSignInMethods";
import { Heading, SubHeading } from "../../components/Typography";

const browser = Bowser.getParser(window.navigator.userAgent);

const os = browser.getOS();

type FormInputs = {
  phone?: string;
  code?: number;
};

const SignInPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    setFocus,
    setError,
    clearErrors,
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      phone: "",
    },
  });

  const contextAuth = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewType, setViewType] = useState("phone");
  const [values, setValues]: [Record<string, string>, Function] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [codeRetries, setCodeRetries] = useState(0);
  const [limitPrompt, setLimitPrompt] = useState(false);
  const [confirm, setConfirm] = useState<any>(null);
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier | null>(null);
  const [phoneValue, setPhoneValue] = useState<string | null>("");
  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  useEffect(() => {
    window.scroll(0, 0);
    setFocus("phone");

    async function onboardingOpened() {
      await sendEvent("follower.onboarding.opened");
    }

    onboardingOpened();
  }, [setFocus]);

  useEffect(() => {
    if (!recaptcha) {
      const verify = new RecaptchaVerifier(
        "recaptcha",
        { size: "invisible" },
        auth
      );
      setRecaptcha(verify);
    }
  }, [recaptcha]);

  useEffect(() => {
    if (codeRetries === 5) {
      setLimitPrompt(true);
      setTimeout(() => {
        setCodeRetries(0);
      }, 120000);
    }
  }, [codeRetries]);

  useEffect(() => {
    if (currentPage === 2) {
      setFocus("code");
    }
  }, [currentPage, setFocus]);

  const submitForm: SubmitHandler<FormInputs> = async (data: any) => {
    if (recaptcha) {
      setIsSubmitting(true);
      const phone = `+${data.phone}`;
      try {
        const result = await signInWithPhoneNumber(auth, phone, recaptcha);
        setIsSubmitting(false);
        setValues(data);
        setConfirm(result);
        toast.success("Confirmation code sent!");
        window.scroll(0, 0);
        setCurrentPage(2);
      } catch (error: any) {
        setIsSubmitting(false);
        const errorMessage = error?.message;
        toast.error(errorMessage);
      }
    } else {
      toast.error("Can not sign up/sign in. Please try again.");
    }
  };

  const confirmationCode: SubmitHandler<FormInputs> = async (data: any) => {
    setIsSubmitting(true);
    if (confirm) {
      confirm
        .confirm(data.code)
        .then(async (result: any) => {
          const idToken = await result.user.getIdToken();
          const refHandle = getCookie(DMS_APP_CREATOR_REF);
          if (contextAuth) {
            await contextAuth.handleLogin(idToken, refHandle);
          } else {
            setIsSubmitting(false);
          }
        })
        .catch((error: any) => {
          console.log(error, "error confirming code");

          setIsSubmitting(false);
          setWarningModal(true);
        });
    }
  };

  const resendCode = async () => {
    if (recaptcha) {
      setValue("code", undefined);
      setIsSubmitting(true);
      setWarningModal(false);
      setShowModal(false);
      const phone = `+${String(getValues("phone"))}`;
      signInWithPhoneNumber(auth, phone, recaptcha)
        .then((result) => {
          setIsSubmitting(false);
          setConfirm(result);
          toast.success("Confirmation code sent!");
          window.scroll(0, 0);
          setCodeRetries((codeRetries) => codeRetries + 1);
        })
        .catch((error) => {
          setIsSubmitting(false);
          const errorMessage = error.message;
          toast.error(errorMessage);
        });
    } else {
      toast.error("Failed to resend code. Try again later!");
    }
  };

  useEffect(() => {
    if (phoneValue) {
      if (!phoneValue.match(/^\D*(\d\D*){11,}$/)) {
        setError("phone", {
          type: "custom",
          message: "Please provide a valid phone number",
        });
        setFormIsValid(false);
      } else {
        clearErrors("phone");
        setFormIsValid(true);
      }
      setValue("phone", phoneValue);
    }
  }, [clearErrors, phoneValue, setError, setValue]);

  const handleResendCode = (e: any) => {
    e.preventDefault();
    setShowModal(true);
  };

  const FirstPage = () => {
    return (
      <>
        <Heading title="Sign Up/Sign In" />
        <SubHeading title="Enter your phone number and we’ll send you a code" />
        <div className="form-group">
          <label htmlFor="phone">Phone number</label>
          <PhoneInput
            country={"us"}
            onlyCountries={["us"]}
            countryCodeEditable={false}
            containerClass="flex_input"
            placeholder="(555) 555-1234"
            disableDropdown={true}
            defaultErrorMessage={errors?.phone?.message}
            value={phoneValue}
            onChange={(phone) => setPhoneValue(phone)}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
          />
          {errors?.phone?.message && (
            <span style={{ color: "#cd3602" }}>* {errors?.phone?.message}</span>
          )}
        </div>
      </>
    );
  };
  const SecondPage = () => {
    return (
      <>
        <Heading
          title={`Enter the code sent to ${formatUsNumber(values?.phone)}`}
        />
        <div className="form-group">
          <label htmlFor="confirmationCode">Confirmation code</label>
          <FormInput
            id="confirmationCode"
            type="number"
            placeholder="Enter code"
            readOnly={isSubmitting}
            errorMessage={errors?.code?.message}
            inputRef={{
              ...register("code", numberFormValidation(true, 6, 6)),
            }}
          />
        </div>
        <div className="signIn__form_anchor">
          {codeRetries === 5 ? (
            <p className="error">You've reached the limit of resending.</p>
          ) : (
            <p>
              Didn't receive code?{" "}
              <button onClick={handleResendCode}>Resend code </button>
            </p>
          )}
        </div>
      </>
    );
  };
  const PreFooter = () => {
    return (
      <div className="signIn__terms">
        <div className="signIn__terms_agreement">
          <p>
            By clicking "Next", I agree to Do Me a Solid's{" "}
            <a
              href="https://domeasolid.co/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://domeasolid.co/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions.
            </a>
          </p>
        </div>

        <p className="signIn__terms_creator">
          Are you a Creator?
          <a
            href="https://www.domeasolid.co/"
            target="_blank"
            rel="noreferrer"
            className="anchor_link"
          >
            {" "}
            Get early access here
          </a>
        </p>
      </div>
    );
  };
  return (
    <React.Fragment>
      <PageTitle title="Sign In" />
      <section className="signIn">
        <form className="signIn__form">
          {currentPage === 1 && <FirstPage />}
          {currentPage === 2 && <SecondPage />}
          <div className="signIn__form_buttons">
            {currentPage === 2 ? (
              <Button
                text="Next"
                type="submit"
                onClick={handleSubmit((data) => confirmationCode(data))}
                loading={isSubmitting}
                disabled={!isValid || isSubmitting}
              />
            ) : (
              <Button
                text="Next"
                type="submit"
                onClick={handleSubmit((data) => submitForm(data))}
                loading={isSubmitting}
                disabled={!formIsValid || isSubmitting}
              />
            )}
          </div>

          {currentPage === 1 && (
            <OtherSignInMethods
              setIsSubmitting={setIsSubmitting}
              isSubmitting={isSubmitting}
            />
          )}
        </form>
        <PreFooter />
        <div id="recaptcha"></div>
        {showModal && (
          <NoCodeModal
            viewType={viewType}
            resend={() => resendCode()}
            loginType={() => {
              setCurrentPage(1);
              setViewType("phone");
              setShowModal(false);
            }}
            close={() => setShowModal(false)}
          />
        )}
        {warningModal && (
          <AlertModal
            type="error"
            title="Unable to Verify"
            btnText="Try again"
            retry={() => {
              setValue("code", undefined);
              setWarningModal(false);
            }}
            message="The code you entered has expired or does not match."
            resend={() => resendCode()}
            close={() => setWarningModal(false)}
          />
        )}
        {limitPrompt && (
          <AlertModal
            type="error"
            title="Something seems off"
            message="You've reached the maximum limit for codes. You can resend another code in 2 minutes."
            btnText="Okay"
            close={() => setLimitPrompt(false)}
          />
        )}
      </section>
    </React.Fragment>
  );
};

export default SignInPage;
