export const DoubleCheck = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#11B8A4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="7 13 11 17 17 11"></polyline>
      <polyline points="7 6 11 10 17 4"></polyline>
    </svg>
  );
};
