export const ChevronRight = ({ style }: { style?: {} }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="16"
        width="16"
        height="16"
        transform="rotate(-90 0 16)"
        fill="white"
        fillOpacity="0.01"
      />
      <mask
        id="mask0_24187_24469"
        style={style}
        maskUnits="userSpaceOnUse"
        x="4"
        y="2"
        width="8"
        height="12"
      >
        <path
          d="M4.86328 3.13802L5.80609 2.19521L11.6108 7.99995L5.80609 13.8047L4.86328 12.8619L9.72521 7.99995L4.86328 3.13802Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_24187_24469)">
        <rect
          y="16"
          width="16"
          height="16"
          transform="rotate(-90 0 16)"
          fill="#4F4F4F"
        />
      </g>
    </svg>
  );
};
