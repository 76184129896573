import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setCookie } from "../../utils/functions";
import { DMS_APP_CREATOR_REF, DMS_EXT_URL } from "../../utils/constants";
import { getInfluencerByHandle } from "../../api";

const Extension = () => {
  const params = useParams();
  const { handle } = params;

  useEffect(() => {
    async function redirectToChromStore() {
      if (handle) {
        const influencer = await getInfluencerByHandle(handle);
        setCookie(DMS_APP_CREATOR_REF, influencer.id, 1);
      }

      window.location.href = DMS_EXT_URL;
    }

    redirectToChromStore();
  }, []);

  return <></>;
};

export default Extension;
