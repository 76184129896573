import React from "react";

interface TypographyProps {
  title: string;
}

export const Heading: React.FC<TypographyProps> = (props) => {
  const { title } = props;

  return <h1 className="heading">{title}</h1>;
};

export const SubHeading: React.FC<TypographyProps> = (props) => {
  const { title } = props;

  return <h3 className="subHeading">{title}</h3>;
};
