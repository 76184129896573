export const Facebook = ({ style }: { style?: {} }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={style}
      d="M6 1.02002C3.25 1.02002 1 3.26502 1 6.03002C1 8.53002 2.83 10.605 5.22 10.98V7.48002H3.95V6.03002H5.22V4.92502C5.22 3.67002 5.965 2.98002 7.11 2.98002C7.655 2.98002 8.225 3.07502 8.225 3.07502V4.31002H7.595C6.975 4.31002 6.78 4.69502 6.78 5.09002V6.03002H8.17L7.945 7.48002H6.78V10.98C7.95822 10.7939 9.0311 10.1928 9.80496 9.28505C10.5788 8.37732 11.0027 7.22284 11 6.03002C11 3.26502 8.75 1.02002 6 1.02002V1.02002Z"
      fill="#002D2B"
    />
  </svg>
);
