import { createSlice } from "@reduxjs/toolkit";

import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";

import toast from "react-hot-toast";
import { checkFollowStatus, fetchInfluencerInfo } from "../../db/influencer";
import {
  addPromoLink,
  getPromoLinks,
  updateLinkStatus,
  saveLinkOrder,
  fetchAllLinkMetrics,
  updateLink,
  deleteLink,
} from "../../db/promolink";
import { followInfluencer, unfollowInfluencer } from "../../db/user";
import { fetchAllCategories } from "../../db/category";

type Props = {
  categories: [] | null;
  promoLinks: [] | null;
  analytics: [] | null;
};
export const initialState: Props = {
  categories: null,
  promoLinks: null,
  analytics: null,
};

// Slice
const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setCategories: (state, { payload }) => {
      state.categories = payload;
    },
    setPromoLinks: (state, { payload }) => {
      state.promoLinks = payload;
    },
    setAnalytics: (state, { payload }) => {
      state.analytics = payload;
    },
  },
});
export default slice.reducer;

// Actions
const { setCategories, setPromoLinks, setAnalytics } = slice.actions;

export const getCategoriesAction = () => async (dispatch: any) => {
  await fetchAllCategories()
    .then((result: any) => {
      dispatch(setCategories(result?.categories));
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });
};

export const getPromoLinksAction = (data: any) => async (dispatch: any) => {
  await getPromoLinks({ influencerId: data })
    .then((result: any) => {
      dispatch(setPromoLinks(result.links));
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });
};

export const addPromoLinkAction = (data: any) => async (dispatch: any) => {
  const res = await addPromoLink(data)
    .then((result: any) => {
      if (result.linkId) {
        return { success: true };
      }
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const updatePromoLinkAction = (data: any) => async (dispatch: any) => {
  const res = await updateLink(data)
    .then((result: any) => {
      if (result) {
        return { success: true };
      }
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const updateLinkStatusAction = (data: any) => async (dispatch: any) => {
  const res = await updateLinkStatus(data)
    .then((result: any) => {
      if (result.success) {
        return { success: true };
      }
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const updateLinksOrderAction = (data: any) => async (dispatch: any) => {
  const res = await saveLinkOrder(data)
    .then((result: any) => {
      if (result.success) {
        return { success: true };
      }
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const deletePromoLinkAction = (data: any) => async (dispatch: any) => {
  const res = await deleteLink(data)
    .then((result: any) => {
      if (result.deleted) {
        return { success: true };
      }
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const fetchAllPromoLinkMetricsAction =
  (data: any) => async (dispatch: any) => {
    await fetchAllLinkMetrics(data)
      .then((result: any) => {
        dispatch(setAnalytics(result));
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });
  };

// User Home functions starts here
export const fetchInfluencerInfoAction =
  (data: any) => async (dispatch: any) => {
    const res = await fetchInfluencerInfo(data)
      .then((result: any) => {
        return result;
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const updateInfluencerViewCountAction = (data: any) => async () => {
  const func = httpsCallable(functions, "updateInfluencerViewCount");
  const res = await func(data)
    .then(() => {
      console.log(".");
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.log("updateInfluencerViewCount Error: ", errorMessage);
    });

  return res;
};

export const updateInfluencerLinkCountAction = (data: any) => async () => {
  const func = httpsCallable(functions, "updateInfluencerLinkCount");
  const res = await func(data)
    .then(() => {
      console.log(".");
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.log("updateInfluencerLinkCount Error: ", errorMessage);
    });

  return res;
};

export const checkFollowStatusAction = (data: any) => async (dispatch: any) => {
  const res = await checkFollowStatus(data)
    .then((result: any) => {
      return result;
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const followInfluencerAction = (data: any) => async (dispatch: any) => {
  const res = await followInfluencer(data)
    .then((result: any) => {
      if (result.success) {
        toast.success("Follow successful!");
        return { success: true };
      }
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const unfollowInfluencerAction =
  (data: any) => async (dispatch: any) => {
    const res = await unfollowInfluencer(data)
      .then((result: any) => {
        if (result.success) {
          toast.success("Unfollow successful!");
          return { success: true };
        }
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };
