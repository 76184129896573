export const Youtube = ({ style }: { style?: {} }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={style}
      d="M10.9828 3.27168C10.9397 3.09886 10.8554 2.93907 10.737 2.806C10.6186 2.67292 10.4697 2.57054 10.3031 2.50762C8.7 1.88887 6.14062 1.89356 6 1.89356C5.85938 1.89356 3.3 1.88887 1.69688 2.50762C1.53025 2.57054 1.38137 2.67292 1.263 2.806C1.14462 2.93907 1.06027 3.09886 1.01719 3.27168C0.895313 3.73574 0.75 4.58887 0.75 5.99981C0.75 7.41074 0.895313 8.26387 1.01719 8.72793C1.06027 8.90075 1.14462 9.06054 1.263 9.19361C1.38137 9.32669 1.53025 9.42907 1.69688 9.49199C3.23438 10.0873 5.64375 10.1061 5.96719 10.1061H6.03281C6.35625 10.1061 8.76563 10.0873 10.3031 9.49199C10.4697 9.42907 10.6186 9.32669 10.737 9.19361C10.8554 9.06054 10.9397 8.90075 10.9828 8.72793C11.1047 8.26387 11.25 7.41074 11.25 5.99981C11.25 4.58887 11.1047 3.73574 10.9828 3.27168V3.27168ZM7.60312 6.15449L5.35312 7.65449C5.32328 7.67646 5.28705 7.68799 5.25 7.68731C5.21895 7.68615 5.18854 7.67815 5.16094 7.66387C5.13098 7.64828 5.10592 7.6247 5.08854 7.59574C5.07117 7.56678 5.06215 7.53358 5.0625 7.49981V4.49981C5.06215 4.46604 5.07117 4.43283 5.08854 4.40387C5.10592 4.37491 5.13098 4.35133 5.16094 4.33574C5.19083 4.3198 5.22449 4.31225 5.25833 4.3139C5.29217 4.31555 5.32493 4.32634 5.35312 4.34512L7.60312 5.84512C7.62927 5.86159 7.65081 5.88441 7.66575 5.91146C7.68068 5.93851 7.68851 5.96891 7.68851 5.99981C7.68851 6.0307 7.68068 6.0611 7.66575 6.08815C7.65081 6.1152 7.62927 6.13803 7.60312 6.15449Z"
      fill="#002D2B"
    />
  </svg>
);
