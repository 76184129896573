import { db } from "../firebase";
import {
  collection,
  limit,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  addDoc,
  arrayRemove,
  arrayUnion,
  writeBatch,
  Timestamp,
  updateDoc,
  DocumentReference,
} from "firebase/firestore";
// import * as EmailValidator from "email-validator";

const userCollection = collection(db, "user");
const influencerCollection = collection(db, "influencer");
const userRecommendationFeedCollection = collection(db, "recommendationFeed");
const defaultRecommendationFeedCollection = collection(
  db,
  "defaultRecommendationFeed"
);
const loginUser = collection(db, "loginUser");

export const createUser = async (data: any) => {
  const name = data.name ? data.name.trim() : "";
  const email = data.email ? data.email.trim() : "";
  const uid = data.uid.trim();

  enum SocialMedia {
    youtube = "youtube",
    instagram = "instagram",
    tiktok = "tiktok",
    discord = "discord",
    twitter = "twitter",
    facebook = "facebook",
    twitch = "twitch",
    other = "other",
  }

  let signupInfluencer = "";
  let signupReferrer: string = SocialMedia.other;

  if (data.refHandle) {
    signupInfluencer = data.refHandle.trim();
  }

  if (data.referrer) {
    const referrerUrl = data.referrer.toLowerCase() || "";

    if (referrerUrl) {
      for (const socialMedia in SocialMedia) {
        if (socialMedia in SocialMedia) {
          if (referrerUrl.indexOf(socialMedia) !== -1) {
            signupReferrer = socialMedia;
            break;
          }
        }
      }
    }
  }

  // if (email && !EmailValidator.validate(email)) {
  //   throw new Error(
  //       "invalid-argument: Email is not valid"
  //   );
  // }

  const userQ = query(userCollection, where("uid", "==", data.uid), limit(1));
  const userSnap = await getDocs(userQ);

  if (!userSnap.empty) {
    throw new Error("UserSnap - already-exists: User already exists");
  }

  const currentTimestamp = Timestamp.now();
  const doc = await addDoc(userCollection, {
    name: name,
    email: email,
    uid: uid,
    following: [],
    categories: [],
    preferences: {
      notifyInfluencerLinksUpdates: false,
      notifyNewFeatures: false,
    },
    signupInfluencer: signupInfluencer,
    signupReferrer: signupReferrer,
    created: currentTimestamp,
  });

  return { userId: doc.id };
};

export const getUserType = async (data: any) => {
  const uid = data.uid.trim();
  const userQ = query(userCollection, where("uid", "==", uid), limit(1));

  const influencerCollection = collection(db, "influencer");
  const influencerQ = query(
    influencerCollection,
    where("uid", "==", uid),
    limit(1)
  );

  const userResult = await getDocs(userQ);
  const influencerResult = await getDocs(influencerQ);

  if (userResult.empty && influencerResult.empty) {
    throw new Error("user doesn't exist");
  }

  if (!userResult.empty && !influencerResult.empty) {
    throw new Error("user exists as both");
  }

  if (!userResult.empty) {
    return { type: "user" };
  } else {
    return { type: "influencer" };
  }
};

export const fetchUserInfo = async (data: any) => {
  const userQ = query(userCollection, where("uid", "==", data.uid), limit(1));
  const userSnap = await getDocs(userQ);
  const userData = userSnap.docs[0].data();

  if (!userData) {
    throw new Error("Failed to fetch influencer info");
  }

  if (userData.following.length) {
    const userFollowersHandleList = [];
    for (const influencerRef of userData.following) {
      const influencerDoc = await getDoc(influencerRef);
      const influencerData = influencerDoc.data() as {
        name: string;
        handle: string;
        socialMediaLinks: any;
        profilePhotoUrl: string;
        categories: string[];
      };

      if (!influencerData) {
        throw new Error("Following influencer not found");
      }
      userFollowersHandleList.push({
        name: influencerData?.name,
        handle: influencerData?.handle,
        socials: influencerData?.socialMediaLinks,
        profilePhotoUrl: influencerData?.profilePhotoUrl,
        categories: influencerData?.categories,
      });
    }
    delete userData.following;
    userData.following = userFollowersHandleList;
  }
  const userDocId = userSnap.docs[0].id;
  userData["id"] = userDocId;
  return userData;
};

export const updateUserCategories = async (data: any) => {
  const categories = data.categories;
  const userId = data.userId.trim();

  const userRef = doc(userCollection, userId);

  const userDoc = await getDoc(userRef);
  if (!userDoc.exists) {
    throw new Error("not-found: User not found");
  }

  await updateDoc(userRef, {
    categories: categories,
  });
  const returnValues: { [key: string]: any } = {};
  returnValues[`${userId}`] = categories;
  return returnValues;
};

export const fetchUserFollowing = async (data: any) => {
  const userQ = query(userCollection, where("uid", "==", data.uid), limit(1));
  const userSnap = await getDocs(userQ);
  const userData = userSnap.docs[0].data();

  if (!userData) {
    throw new Error("Failed to fetch influencer info");
  }

  if (userData.following.length) {
    const userFollowersHandleList = [];
    for (const influencerRef of userData.following) {
      const influencerDoc = await getDoc(influencerRef);
      const influencerData = influencerDoc.data() as { handle: string };
      if (!influencerData) {
        throw new Error("Following influencer not found");
      }
      userFollowersHandleList.push(influencerData.handle);
    }
    delete userData.following;
    userData.following = userFollowersHandleList;
  }
  const userDocId = userSnap.docs[0].id;
  userData["id"] = userDocId;
  return userData;
};

export const followInfluencer = async (data: any) => {
  const influencerHandle = data.handle.trim();
  const userId = data.userId.trim();

  const influencerQ = query(
    influencerCollection,
    where("handle", "==", influencerHandle),
    limit(1)
  );
  const userRef = doc(db, "user", userId);

  const influencerQSnap = await getDocs(influencerQ);

  if (influencerQSnap.empty) {
    throw new Error("Influencer not found");
  }
  const influencerFollowerCount =
    influencerQSnap.docs[0].data().followerCount || 0;
  const influencerId = influencerQSnap.docs[0].id;
  const influencerRef = doc(db, "influencer", influencerId);
  const user = await getDoc(userRef);

  if (!user.exists) {
    throw new Error("User not found");
  }

  try {
    // Create a batch to perform multiple writes atomically
    const batch = writeBatch(db);
    batch.update(userRef, {
      following: arrayUnion(influencerRef),
    });
    batch.set(
      influencerRef,
      { followerCount: influencerFollowerCount + 1 },
      { merge: true }
    );

    // Commit the batch to save the changes
    await batch.commit();

    return { success: true };
  } catch (error) {
    throw new Error("An error occurred while saving the custom order.");
  }
};

export const unfollowInfluencer = async (data: any) => {
  const influencerHandle = data.handle.trim();
  const userId = data.userId.trim();

  const userRef = doc(db, "user", userId);
  const influencerQ = query(
    influencerCollection,
    where("handle", "==", influencerHandle),
    limit(1)
  );

  const influencerQSnap = await getDocs(influencerQ);

  if (influencerQSnap.empty) {
    throw new Error("Influencer not found");
  }
  const influencerFollowerCount =
    influencerQSnap.docs[0].data().followerCount || 0;
  const influencerId = influencerQSnap.docs[0].id;
  const influencerRef = doc(db, "influencer", influencerId);
  const user = await getDoc(userRef);

  if (!user.exists) {
    throw new Error("User not found");
  }

  try {
    // Create a batch to perform multiple writes atomically
    const batch = writeBatch(db);
    batch.update(userRef, {
      following: arrayRemove(influencerRef),
    });

    if (influencerFollowerCount) {
      batch.set(
        influencerRef,
        { followerCount: influencerFollowerCount - 1 },
        { merge: true }
      );
    }
    // Commit the batch to save the changes
    await batch.commit();

    return { success: true };
  } catch (error) {
    throw new Error("An error occurred while saving the custom order.");
  }
};

export const fetchUserRecommendation = async (data: any) => {
  const userId = data.userId;
  const following: DocumentReference[] = data.following || [];
  const followingSet = new Set(following.map((ref) => ref.id));
  const userRef = doc(db, "user", userId);

  const filterRecommendedRefs = async (
    recommendedRefs: DocumentReference[]
  ) => {
    const defaultInfluencers: any = [];
    let i: number = 0;
    const filterSize: number = 5;
    for (const influencerRef of recommendedRefs) {
      if (i > filterSize - 1) {
        break;
      }
      if (!followingSet.has(influencerRef.id)) {
        const influencerSnap = await getDoc(influencerRef);
        const influencerData = influencerSnap.data();

        if (influencerData) {
          const influencerDisplayData = {
            name: influencerData.name || "",
            handle: influencerData.handle,
            categories: influencerData.categories || [],
            profilePhotoUrl: influencerData.profilePhotoUrl || "",
          };
          defaultInfluencers.push(influencerDisplayData);
          i++;
        }
      }
    }
    return defaultInfluencers;
  };

  const recommendedQ = query(
    userRecommendationFeedCollection,
    where("user", "==", userRef),
    limit(1)
  );
  const recommendedQSnap = await getDocs(recommendedQ);
  let defaultInfluencers: any = [];
  if (recommendedQSnap.empty) {
    const defaultRecommendedSnap = await getDocs(
      defaultRecommendationFeedCollection
    );
    const defaultDoc = defaultRecommendedSnap.docs[0];
    const defaultData = defaultDoc.data();
    defaultInfluencers = await filterRecommendedRefs(defaultData.influencers);
    return { default: defaultInfluencers, category: [] };
  }

  const recommendedDoc = recommendedQSnap.docs[0];
  const recommendedData = recommendedDoc.data();
  let categoricalInfluencers: any = [];

  const defaultInfluencerRefs = recommendedData.defaultInfluencers;
  const categorialInfluencersLength =
    recommendedData.categoricalInfluencers.length || 0;
  const categoricalInfluencerRefs =
    categorialInfluencersLength > 0
      ? recommendedData.categoricalInfluencers
      : recommendedData.defaultInfluencers;

  defaultInfluencers = await filterRecommendedRefs(defaultInfluencerRefs);
  categoricalInfluencers = await filterRecommendedRefs(
    categoricalInfluencerRefs
  );

  return { default: defaultInfluencers, category: categoricalInfluencers };
};

export const updateUserBio = async (data: any) => {
  const userDocRef = doc(db, "user", data.userId);
  await updateDoc(userDocRef, { name: data.name });
  return { success: true };
};

export const sendLoginEmail = async (userEmail: string, actionLink: string) => {
  const doc = await addDoc(loginUser, {
    userEmail: userEmail,
    actionLink: actionLink,
  });
  return { success: true };
};

export const doesUserExist = async (data: any) => {
  const q = query(
    userCollection,
    where("uid", "==", data.uid.trim()),
    limit(1)
  );

  const userQ = await getDocs(q);

  if (userQ.empty) {
    return { userExists: false };
  } else {
    return { userExists: true };
  }
};
