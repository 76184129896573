/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import PageTitle from "../../components/page-title";
import { appStore, mockup } from "../../assets/img";
import { mockup2 } from "../../assets/img";
import { qrCode } from "../../assets/img";
import { Heading, SubHeading } from "../../components/Typography";

const Complete = () => {
  // const { user } = useSelector((state: any) => state.userSlice);
  // if (!user?.uid) return <>{window.location.assign("/")}</>;

  return (
    <React.Fragment>
      <PageTitle title="Sign In Complete" />

      <div className="complete">
        {" "}
        <Heading
          title=" You’re signed into the extension. Start shopping, and we’ll meet you
            at checkout."
        />
        <div className="complete__image">
          {" "}
          <div className="complete__image_left">
            <SubHeading title="Download our app and enable the Safari extension to get creator discounts on your mobile device." />
            <div className="complete__image_left-links">
              <img src={qrCode} className="store-img" alt="DMAS qrCode" />
              <a
                href="https://apps.apple.com/us/app/do-me-a-solid/id6692632453"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appStore} alt="DMAS qrCode" />
              </a>
            </div>
          </div>
          <div className="complete__image_right">
            <img src={mockup} alt="DMAS qrCode" />
            <img src={mockup2} alt="DMAS qrCode" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Complete;
