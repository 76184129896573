export const Google = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_19851_14263)">
      <path
        d="M3.75 8.00012C3.75 7.2074 3.96841 6.4648 4.34784 5.82896V3.13184H1.65072C0.58025 4.52209 0 6.21643 0 8.00012C0 9.7838 0.58025 11.4781 1.65072 12.8684H4.34784V10.1713C3.96841 9.53543 3.75 8.79284 3.75 8.00012Z"
        fill="#FBBD00"
      />
      <path
        d="M8 12.2502L6.125 14.1252L8 16.0002C9.78372 16.0002 11.478 15.42 12.8683 14.3495V11.6553H10.174C9.53262 12.0361 8.78691 12.2502 8 12.2502Z"
        fill="#0F9D58"
      />
      <path
        d="M4.34782 10.1709L1.6507 12.868C1.86263 13.1433 2.09341 13.4069 2.34313 13.6566C3.85413 15.1676 5.8631 15.9997 7.99998 15.9997V12.2497C6.44923 12.2497 5.09007 11.4147 4.34782 10.1709Z"
        fill="#31AA52"
      />
      <path
        d="M16 8.00013C16 7.51341 15.9559 7.02579 15.869 6.55085L15.7987 6.1665H8V9.91651H11.7954C11.4268 10.6497 10.8601 11.2478 10.174 11.6552L12.8682 14.3494C13.1435 14.1375 13.4071 13.9067 13.6568 13.657C15.1678 12.146 16 10.137 16 8.00013Z"
        fill="#3C79E6"
      />
      <path
        d="M11.0052 4.99478L11.3367 5.32622L13.9883 2.67459L13.6569 2.34316C12.1459 0.832156 10.1369 0 8 0L6.125 1.875L8 3.75C9.13519 3.75 10.2025 4.19206 11.0052 4.99478Z"
        fill="#CF2D48"
      />
      <path
        d="M8.00001 3.75V0C5.86313 0 3.85417 0.832156 2.34313 2.34313C2.09341 2.59284 1.86263 2.85644 1.6507 3.13172L4.34782 5.82884C5.0901 4.585 6.44926 3.75 8.00001 3.75Z"
        fill="#EB4132"
      />
    </g>
    <defs>
      <clipPath id="clip0_19851_14263">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
