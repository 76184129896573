import Button from "../button";

type Props = {
  close: () => void;
  resend: () => void;
  loginType: () => void;
  viewType: string;
};

const NoCodeModal = ({ close, resend, viewType, loginType }: Props) => {
  return (
    <div className="modal_container">
      <div className="modal_content size_md fixed dummy_modal">
        <div className="action">
          <Button
            type="button"
            text={viewType == "phone" ? "Resend code" : "Resend email link"}
            btnStyle={{ width: "360px" }}
            onClick={resend}
          />
        </div>
        <div className="action">
          <Button
            text="Help page"
            btnStyle={{ width: "360px" }}
            onClick={() => window.open("https://domeasolid.co")}
          />
        </div>
        <div className="action">
          <Button text="Cancel" btnStyle={{ width: "360px" }} onClick={close} />
        </div>
      </div>
    </div>
  );
};

export default NoCodeModal;
