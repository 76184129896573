export const Category = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8553 1.33398H13.033C13.934 1.33398 14.6649 2.07125 14.6649 2.98077V5.17672C14.6649 6.08555 13.934 6.82351 13.033 6.82351H10.8553C9.95358 6.82351 9.22266 6.08555 9.22266 5.17672V2.98077C9.22266 2.07125 9.95358 1.33398 10.8553 1.33398Z"
        stroke="#002D2B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.96465 1.33398H5.14169C6.04339 1.33398 6.77431 2.07125 6.77431 2.98077V5.17672C6.77431 6.08555 6.04339 6.82351 5.14169 6.82351H2.96465C2.06295 6.82351 1.33203 6.08555 1.33203 5.17672V2.98077C1.33203 2.07125 2.06295 1.33398 2.96465 1.33398Z"
        stroke="#002D2B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.96465 9.17773H5.14169C6.04339 9.17773 6.77431 9.915 6.77431 10.8252V13.0205C6.77431 13.93 6.04339 14.6673 5.14169 14.6673H2.96465C2.06295 14.6673 1.33203 13.93 1.33203 13.0205V10.8252C1.33203 9.915 2.06295 9.17773 2.96465 9.17773Z"
        stroke="#002D2B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8553 9.17773H13.033C13.934 9.17773 14.6649 9.915 14.6649 10.8252V13.0205C14.6649 13.93 13.934 14.6673 13.033 14.6673H10.8553C9.95358 14.6673 9.22266 13.93 9.22266 13.0205V10.8252C9.22266 9.915 9.95358 9.17773 10.8553 9.17773Z"
        stroke="#002D2B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
