import { baseURL } from "../utils/constants";

async function baseAPIRequest(
  method: string,
  path: string,
  headers: any = {},
  body: any = {}
) {
  const reqParameters: Record<string, any> = {
    method,
    credentials: "include",
  };

  if (Object.keys(headers).length > 0) {
    reqParameters.headers = headers;
  }

  if (method === "POST" || method === "PUT" || method === "PATCH") {
    reqParameters.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(`${baseURL}/${path}`, reqParameters);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  } catch (error) {
    console.error("Failed to fetch:", error);
    throw error;
  }
}

async function sendEvent(eventName: string) {
  try {
    await baseAPIRequest(
      "POST",
      `analytics/event`,
      {
        "Content-Type": "application/json",
      },
      {
        eventName,
      }
    );
  } catch (error) {
    console.error("Error sending event:", error);
  }
}

async function getInfluencerByHandle(handle: string) {
  try {
    const response = await baseAPIRequest(
      "GET",
      `api/v1/influencers/handle/${handle}`
    );

    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else {
      console.error(
        `Failed to fetch influencer: HTTP status ${response.status}`
      );
      return null;
    }
  } catch (error) {}
}

async function sendExtensionEmail(email: string, influencerHandle: string) {
  try {
    await baseAPIRequest(
      "POST",
      `auth/extension/email`,
      {
        "Content-Type": "application/json",
      },
      {
        email,
        influencerHandle,
      }
    );
  } catch (error) {
    console.log("Error sending extension email", error);
  }
}

export { getInfluencerByHandle, sendEvent, sendExtensionEmail };
