import Button from "../button";

import { warning } from "../../assets/img";
import { success } from "../../assets/img";

type Props = {
  type: string;
  title: string;
  message?: string;
  resend?: () => void;
  close: () => void;
  btnText: string;
  retry?: () => void;
};

const AlertModal = ({
  type,
  title,
  message,
  resend,
  close,
  btnText,
  retry,
}: Props) => {
  return (
    <div className="modal_container">
      <div className="modal_content size_sm dummy_modal">
        <div className="body">
          <img
            src={type === "success" ? success : warning}
            style={{ width: "70px", marginBottom: "10px" }}
            alt="Warning"
          />
          <p
            style={{ marginTop: "0px", marginBottom: "10px", fontSize: "20px" }}
          >
            {title}
          </p>
          <h4>{message}</h4>

          {retry ? (
            <Button type="button" text={btnText} onClick={retry} />
          ) : (
            <Button type="button" text={btnText} onClick={close} />
          )}
          {resend && (
            <Button
              text="Resend Code"
              type="submit"
              btnStyle={{ backgroundColor: "white", color: "#002D2B" }}
              onClick={resend}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
