import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

const categoryCollection = collection(db, "category");

export const fetchAllCategories = async () => {
  const queryResult = await getDocs(categoryCollection);

  if (queryResult.empty) {
    return { categories: [] };
  }

  return {
    categories: queryResult.docs.map((doc) => {
      const categoryData = doc.data();
      return categoryData.name;
    }),
  };
};
