import Icon from "../assets/svg";

type Props = {
  headText: string;
  close: () => void;
  subText?: string;
  icon?: string;
  alignText?: string;
};

const SectionHeader = ({
  headText,
  close,
  subText,
  icon,
  alignText,
}: Props) => {
  return (
    <div className="section_header_container">
      <div className={`head_text ${alignText ? "middle" : ""}`}>
        <h6>{headText}</h6>
        {close && (
          <div className="close" onClick={close}>
            <Icon name={icon ? icon : "close"} />
          </div>
        )}
      </div>

      {subText && <p className="sub_text">{subText}</p>}
    </div>
  );
};

export default SectionHeader;
