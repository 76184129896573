export const Bell = ({ style }: { style?: {} }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" fillOpacity="0.01" />
      <mask
        id="mask0_24187_24480"
        style={style}
        maskUnits="userSpaceOnUse"
        x="1"
        y="0"
        width="14"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6654 6.66601C12.6654 3.9599 11.1731 2.08168 9.24229 1.51782C9.17238 1.33692 9.06188 1.16875 8.91169 1.02764C8.39852 0.545476 7.59888 0.545476 7.08571 1.02764C6.93585 1.16844 6.82551 1.33618 6.75558 1.51663C4.82227 2.07803 3.33203 3.95214 3.33203 6.66601C3.33203 8.42194 2.97802 9.40586 2.32267 10.1189C2.21312 10.2381 1.91633 10.5111 1.71524 10.6961L1.71516 10.6962L1.71513 10.6962C1.61999 10.7837 1.54629 10.8515 1.52401 10.874L1.33203 11.0687V13.3421H5.39067C5.52791 14.0501 5.91208 14.5238 6.60682 14.9478C7.46145 15.4693 8.53592 15.4693 9.39055 14.9478C10.0037 14.5736 10.4148 13.9986 10.5816 13.3421H14.6654V11.0687L14.4734 10.874C14.4532 10.8535 14.3826 10.7883 14.2902 10.7032L14.2902 10.7031L14.29 10.703C14.0896 10.518 13.7869 10.2388 13.6756 10.1173C13.0198 9.40094 12.6654 8.41577 12.6654 6.66601ZM9.1565 13.3421H6.78443C6.87862 13.5173 7.03916 13.6496 7.30136 13.8096C7.72953 14.0709 8.26784 14.0709 8.69601 13.8096C8.89428 13.6886 9.04921 13.5285 9.1565 13.3421ZM12.6922 11.0176C12.8324 11.1709 13.1331 11.451 13.332 11.634V12.0087H2.66537V11.6343C2.86275 11.4534 3.16432 11.1735 3.30436 11.0212C4.19485 10.0523 4.66537 8.74455 4.66537 6.66602C4.66537 4.14595 6.22055 2.67427 7.9987 2.67427C9.77184 2.67427 11.332 4.15354 11.332 6.66602C11.332 8.73788 11.8027 10.046 12.6922 11.0176Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_24187_24480)">
        <rect width="16" height="16" fill="#002D2B" />
      </g>
    </svg>
  );
};
