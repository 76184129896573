import React from "react";

import { Dashboard } from "./dashboard";
import { Settings } from "./settings";
import { Logout } from "./logout";
import { Extension } from "./extension";
import { Close } from "./close";
import { Facebook } from "./facebook";
import { Google } from "./google";
import { Instagram } from "./instagram";
import { Menu } from "./menu";
import { Tiktok } from "./tiktok";
import { Twitter } from "./twitter";
import { Youtube } from "./youtube";
import { Creators } from "./creators";
import { Home } from "./home";
import { Profile } from "./profile";
import { Notification } from "./notification";
import { Avatar } from "./avatar";
import { ExtensionGreen } from "./ext-green";
import { ExtensionPlain } from "./ext-plain";
import { Cancel } from "./cancel";
import { Users } from "./users";
import { CloseSquare } from "./close-square";
import { ArrowLeft } from "./arrow-left";
import { ChevronRight } from "./chevron-right";
import { Bell } from "./bell";
import { Category } from "./category";
import { PinQuestion } from "./pin-question";
import { Camera } from "./camera";
import { DoubleCheck } from "./double-check";
import { Bulb } from "./bulb";
import { NotFollowing } from "./not-following";
import { NoCreators } from "./no-creators";
import { Twitch } from "./twitch";

const Icon = ({ name, style }: { name: string; style?: {} }) => {
  switch (name) {
    case "close":
      return <Close />;
    case "dashboard":
      return <Dashboard />;
    case "settings":
      return <Settings />;
    case "logout":
      return <Logout />;
    case "extension":
      return <Extension />;
    case "creators":
      return <Creators style={style} />;
    case "home":
      return <Home style={style} />;
    case "profile":
      return <Profile style={style} />;
    case "avatar":
      return <Avatar />;
    case "extension-green":
      return <ExtensionGreen />;
    case "extension-plain":
      return <ExtensionPlain />;
    case "cancel":
      return <Cancel />;
    case "notification":
      return <Notification />;
    case "users":
      return <Users />;
    case "close-square":
      return <CloseSquare />;
    case "arrow-left":
      return <ArrowLeft />;
    case "chevron-right":
      return <ChevronRight style={style} />;
    case "bell":
      return <Bell style={style} />;
    case "category":
      return <Category />;
    case "pin-question":
      return <PinQuestion />;
    case "camera":
      return <Camera />;
    case "double-check":
      return <DoubleCheck />;
    case "bulb":
      return <Bulb />;
    case "not-following":
      return <NotFollowing />;
    case "no-creators":
      return <NoCreators />;
    case "facebook":
      return <Facebook style={style} />;
    case "google":
      return <Google />;
    case "instagram":
      return <Instagram style={style} />;
    case "menu":
      return <Menu />;
    case "tiktok":
      return <Tiktok style={style} />;
    case "twitter":
      return <Twitter style={style} />;
    case "youtube":
      return <Youtube style={style} />;
    case "twitch":
      return <Twitch style={style} />;
    default:
      return <Close />;
  }
};

export default Icon;
