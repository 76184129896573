export const Notification = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" fill="white" fillOpacity="0.01" />
    <mask
      id="mask0_24450_20729"
      maskUnits="userSpaceOnUse"
      x="1"
      y="0"
      width="18"
      height="20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8346 8.333C15.8346 4.95036 13.9693 2.60259 11.5558 1.89776C11.4684 1.67164 11.3303 1.46142 11.1425 1.28503C10.5011 0.682333 9.50154 0.682333 8.86007 1.28503C8.67275 1.46104 8.53482 1.67071 8.4474 1.89627C6.03077 2.59802 4.16797 4.94066 4.16797 8.333C4.16797 10.5279 3.72546 11.7578 2.90627 12.6491C2.7693 12.7981 2.39821 13.1395 2.14684 13.3708L2.14683 13.3708C2.02791 13.4802 1.9358 13.5649 1.90794 13.593L1.66797 13.8363V16.6781H6.74126C6.91281 17.5631 7.39303 18.1552 8.26145 18.6852C9.32974 19.3371 10.6728 19.3371 11.7411 18.6852C12.5075 18.2175 13.0214 17.4987 13.2299 16.6781H18.3346V13.8363L18.0947 13.593C18.0694 13.5674 17.9812 13.486 17.8659 13.3796L17.8657 13.3794L17.8655 13.3792L17.8655 13.3792C17.6149 13.148 17.2365 12.799 17.0975 12.6471C16.2777 11.7517 15.8346 10.5202 15.8346 8.333ZM11.4486 16.6781H8.48346C8.6012 16.8971 8.80187 17.0625 9.12963 17.2625C9.66484 17.5891 10.3377 17.5891 10.8729 17.2625C11.1208 17.1112 11.3144 16.9111 11.4486 16.6781ZM15.8681 13.7725C16.0435 13.9641 16.4193 14.3142 16.668 14.5429V15.0114H3.33464V14.5433C3.58137 14.3173 3.95834 13.9674 4.13338 13.7769C5.24649 12.5658 5.83464 10.9312 5.83464 8.33301C5.83464 5.18292 7.77862 3.34333 10.0013 3.34333C12.2177 3.34333 14.168 5.19242 14.168 8.33301C14.168 10.9228 14.7563 12.558 15.8681 13.7725Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_24450_20729)">
      <rect width="20" height="20" fill="#002D2B" />
    </g>
  </svg>
);
