export const Creators = ({ style }: { style?: {} }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="carbon:intent-request-active">
      <path
        style={style}
        id="Vector"
        d="M11.5 13.5998L10.2 12.2998L9.5 12.9998L11.5 14.9998L15.5 10.9998L14.8 10.2998L11.5 13.5998Z"
        fill="#888888"
      />
      <path
        style={style}
        id="Vector_2"
        d="M7.7 15L2.5 11.9C2.2 11.7 2 11.4 2 11.05V4.95C2 4.6 2.2 4.25 2.5 4.1L7.5 1.15C7.65 1.05 7.8 1 8 1C8.2 1 8.35 1.05 8.5 1.15L13.5 4.1C13.8 4.3 14 4.6 14 4.95V8H13V4.95L8 2L3 4.95V11.05L8.25 14.15L7.7 15Z"
        fill="#888888"
      />
    </g>
  </svg>
);
