export const Camera = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Camera">
        <path
          id="Stroke 1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7574 3.73983V3.73983C10.5087 3.73983 10.2834 3.5965 10.1774 3.3725C9.98603 2.96717 9.7427 2.44917 9.5987 2.16717C9.38603 1.74783 9.04136 1.50383 8.56403 1.5005C8.55603 1.49983 5.44136 1.49983 5.43336 1.5005C4.95603 1.50383 4.61203 1.74783 4.3987 2.16717C4.25536 2.44917 4.01203 2.96717 3.8207 3.3725C3.7147 3.5965 3.4887 3.73983 3.2407 3.73983V3.73983C1.91003 3.73983 0.832031 4.81783 0.832031 6.14783V10.1052C0.832031 11.4345 1.91003 12.5132 3.2407 12.5132H10.7574C12.0874 12.5132 13.1654 11.4345 13.1654 10.1052V6.14783C13.1654 4.81783 12.0874 3.73983 10.7574 3.73983Z"
          stroke="#2F2F2F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Stroke 3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.87897 7.88891C4.87831 9.05758 5.83231 10.0136 6.99897 10.0129C8.16364 10.0116 9.11497 9.05958 9.11697 7.89358C9.11897 6.72291 8.16831 5.76891 7.00031 5.76758C5.82564 5.76625 4.86964 6.73625 4.87897 7.88891Z"
          stroke="#2F2F2F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Fill 5"
          d="M10.2657 6.33006C10.2013 6.32345 10.1377 6.30664 10.0665 6.27796C9.9872 6.24275 9.91859 6.19796 9.85225 6.13057C9.73308 6.0038 9.66406 5.83802 9.66406 5.66699C9.66406 5.57614 9.68264 5.4861 9.71891 5.40454C9.75525 5.3219 9.79833 5.25219 9.87618 5.17637C9.9354 5.12479 9.99607 5.08329 10.079 5.04675C10.3249 4.94931 10.6175 5.00753 10.8003 5.19027C10.8551 5.24426 10.9012 5.3094 10.9268 5.36434L10.942 5.40329C10.9788 5.4861 10.9974 5.57614 10.9974 5.66699C10.9974 5.84148 10.9293 6.00285 10.8028 6.13718C10.6935 6.2472 10.55 6.31487 10.3969 6.33006L10.3307 6.33333L10.2657 6.33006Z"
          fill="#2F2F2F"
        />
      </g>
    </svg>
  );
};
