import React from "react";
import toast from "react-hot-toast";
import { auth } from "../../firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getCookie } from "../../utils/functions";
import { DMS_APP_CREATOR_REF } from "../../utils/constants";
import { useAuth } from "../../providers/auth";
import { google_sso } from "../../assets/img";

interface ISigninOptions {
  setIsSubmitting: (isSubmitting: boolean) => void;
  isSubmitting: boolean;
}

const OtherSignInMethods = ({
  setIsSubmitting,
  isSubmitting,
}: ISigninOptions) => {
  const googleProvider = new GoogleAuthProvider();
  const contextAuth = useAuth();

  const googleSignIn = async () => {
    setIsSubmitting(true);

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const idToken = await result.user.getIdToken();
      const refHandle = getCookie(DMS_APP_CREATOR_REF);

      if (contextAuth) {
        await contextAuth.handleLogin(idToken, refHandle);
      } else {
        setIsSubmitting(false);
        toast.error("Authentication context is not available.");
      }
    } catch (error: any) {
      setIsSubmitting(false);
      const errorCode = error?.code;
      const errorMessage = error?.message;
      console.error("Google sign-in error:", error);
      toast.error(`Error (${errorCode}): ${errorMessage}`);
    }
  };

  return (
    <div>
      <div className="divider">
        <hr className="line" />
        <span className="or">OR</span>
        <hr className="line" />
      </div>

      <button
        className="social-login google"
        type="button"
        onClick={googleSignIn}
        disabled={isSubmitting}
      >
        <img src={google_sso} alt="Google logo" />
        <span>Continue with Google</span>
      </button>
    </div>
  );
};

export default OtherSignInMethods;
