export const Avatar = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Avatar">
        <rect width="35" height="35" fill="white" fillOpacity="0.01" />
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.6153 29.6258C27.8581 29.6257 28.1022 29.6335 28.3434 29.6125C29.3294 29.5265 29.8707 28.7226 29.5716 27.7758C29.491 27.5208 29.3698 27.2741 29.2366 27.041C27.8376 24.5909 25.9605 22.3374 23.3291 21.185C22.9591 21.0229 22.5851 20.8665 22.2261 20.682C20.7654 19.9308 20.3185 18.5209 21.0776 17.0744C21.405 16.4507 21.8501 15.9058 22.255 15.3335C24.1905 12.5981 23.8532 9.04076 21.4365 6.83978C19.7668 5.31931 17.7787 5.00496 15.6967 5.64588C13.6018 6.29067 12.4116 7.91364 11.9965 9.98566C11.5624 12.1514 11.9269 13.943 13.3134 15.7539C13.7396 16.3105 14.1345 16.8909 14.3864 17.5545C14.8758 18.8429 14.4587 19.9106 13.2168 20.5132C12.9507 20.6422 12.6698 20.7409 12.3943 20.8499C11.4657 21.2176 10.5892 21.9218 9.73011 22.4346C7.66623 23.6667 6.45301 25.5855 5.46525 27.6883C5.43108 27.761 5.40913 27.8404 5.38969 27.9187C5.19258 28.7135 5.67646 29.4643 6.4867 29.5883C6.77792 29.6329 7.07861 29.6246 7.37485 29.6248C10.7483 29.627 14.1217 29.6263 17.4951 29.6263C20.8685 29.6263 24.2419 29.6265 27.6153 29.6258ZM11.0777 16.7883C9.23196 14.2371 8.60241 9.70669 10.9452 6.3879C12.5085 4.17324 14.5593 2.99763 17.2707 2.9204C21.1785 2.80909 24.7293 5.43596 25.6153 9.24355C26.2892 12.1402 25.7998 14.5853 24.014 17.0288C23.7135 17.4401 23.1711 17.8488 23.2487 18.3124C23.3339 18.8217 24.0535 18.8455 24.5006 19.0527C27.7242 20.5464 30.0039 23.2786 31.6068 26.3902C32.0694 27.2882 32.2219 28.2455 31.9498 29.2155C31.509 30.7869 30.4706 31.7491 28.8354 32.007C28.4648 32.0654 28.0838 32.0788 27.7075 32.079C20.9073 32.0832 14.1071 32.0811 7.30683 32.0837C6.71087 32.0839 6.11863 32.0598 5.54786 31.8745C3.49224 31.207 2.35944 28.9611 3.19487 26.9256C4.34379 24.1262 6.02103 21.455 8.72994 19.9125C9.78597 19.3113 10.901 18.842 12.0896 18.3488C11.8065 17.7212 11.4232 17.266 11.0777 16.7883Z"
          fill="#00CBC2"
        />
      </g>
    </svg>
  );
};
