export const Home = ({ style }: { style?: {} }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Home">
      <path
        style={style}
        id="Home_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3395 5.65929C19.0095 6.17829 19.4205 6.94929 19.4905 7.78829L19.5005 7.98929V16.0983C19.5005 18.1883 17.8495 19.8883 15.7805 19.9983H13.7905C12.8395 19.9793 12.0705 19.2393 12.0005 18.3093L11.9905 18.1683V15.3093C11.9905 14.9983 11.7595 14.7393 11.4505 14.6883L11.3605 14.6783H8.68949C8.37049 14.6883 8.11049 14.9183 8.07049 15.2183L8.06049 15.3093V18.1593C8.06049 18.2183 8.04949 18.2883 8.04049 18.3383L8.03049 18.3593L8.01949 18.4283C7.90049 19.2793 7.20049 19.9283 6.33049 19.9893L6.20049 19.9983H4.41049C2.32049 19.9983 0.610488 18.3593 0.500488 16.2983V7.98929C0.509488 7.13829 0.880488 6.34829 1.50049 5.79829L7.73049 0.788294C9.00049 -0.220706 10.7805 -0.260706 12.0895 0.668294L12.2505 0.788294L18.3395 5.65929ZM18.0995 16.2583L18.1105 16.0983V7.99829C18.0995 7.56929 17.9205 7.16829 17.6105 6.86929L17.4805 6.75829L11.3805 1.87829C10.6205 1.26829 9.54049 1.23929 8.74049 1.76829L8.58949 1.87829L2.50949 6.77929C2.16049 7.03829 1.95049 7.42829 1.90049 7.83829L1.88949 7.99829V16.0983C1.88949 17.4283 2.92949 18.5183 4.25049 18.5983H6.20049C6.42049 18.5983 6.61049 18.4493 6.63949 18.2393L6.66049 18.0593L6.67049 18.0083V15.3093C6.67049 14.2393 7.49049 13.3693 8.54049 13.2883H11.3605C12.4295 13.2883 13.2995 14.1093 13.3805 15.1593V18.1683C13.3805 18.3783 13.5305 18.5593 13.7305 18.5983H15.5895C16.9295 18.5983 18.0195 17.5693 18.0995 16.2583Z"
        fill="#200E32"
      />
    </g>
  </svg>
);
