import {
  DMS_APP_CREATORS_FEED,
  DMS_APP_INFLUENCERS,
  DMS_APP_USER,
  DMS_APP_USER_TYPE,
} from "./constants";

import { parseDomain, ParseResultType } from "parse-domain";

// Local storage operations
export const useLocalStorage = {
  set: (key: string, data: any) => {
    let stringifiedData = JSON.stringify(data);
    localStorage.setItem(key, stringifiedData);
  },

  get: (key: string) => {
    const data: any = JSON.parse(localStorage.getItem(key)!);

    if (!data) {
      return null;
    }
    return data;
  },

  remove: (key: string) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export const getUserDetails = () => {
  const user = useLocalStorage.get(DMS_APP_USER);

  return user ? user : null;
};

export const getUserTypeDetails = () => {
  const userType = useLocalStorage.get(DMS_APP_USER_TYPE);

  return userType ? userType : null;
};

export const getRecommendationFeed = () => {
  const recommendation = useLocalStorage.get(DMS_APP_CREATORS_FEED);

  return recommendation ? recommendation : null;
};

export const getAllInfluencers = () => {
  const influencers = useLocalStorage.get(DMS_APP_INFLUENCERS);
  return influencers ? influencers : null;
};

export const getRequestError = (error: any) => {
  const { response } = error;

  if (response && response?.data?.errors) {
    const errorMessage = Object.values(response?.data?.errors)[0] || "";
    return errorMessage;
  } else if (response && response?.data?.message) {
    return response?.data?.message;
  }
  return "There might be a problem with your internet connection. Please check and try again.";
};

export const logout = () => {
  const userType = getUserTypeDetails();

  useLocalStorage.remove(DMS_APP_USER);
  useLocalStorage.remove(DMS_APP_USER_TYPE);
  window.scrollTo(0, 0);
  window.location.assign(userType !== "user" ? "/?t=creator" : "/");
};

export const formatDateInWords = (date: string) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const current_date = new Date(date);
  const month_value = current_date.getMonth();
  const day_value = current_date.getDate();
  const year = current_date.getUTCFullYear();
  let hour: any = current_date.getHours();
  let mins: any = current_date.getMinutes();

  if (hour < 10) hour = `0${hour}`;
  if (mins < 10) mins = `0${mins}`;

  return date
    ? `${day_value} ${months[month_value]}, ${year} | ${hour}:${mins}`
    : "";
};

export const formatNumber = (n: number, decimals: number) => {
  return (
    n &&
    Number(n)
      .toFixed(decimals || 0)
      .replace(/./g, function (c, i, a) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      })
  );
};

export const firstLetter = (letter: string) => {
  var str = letter;
  var res = str?.substring(0, 1);
  return res;
};

export const formatUsNumber = (numbers: string) => {
  if (!numbers) return "";
  let cleaned = numbers.replace(/\D/g, "");
  if (cleaned.length === 11) {
    cleaned = cleaned.substring(1, cleaned.length);
    return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
  } else {
    return cleaned;
  }
};

export const revertUsNumberFormat = (numbers: string) => {
  const cleanNumber = numbers.replace(/\D/g, "");
  return cleanNumber;
};

// Form Validations
export const emailFormValidation = (required: boolean) => {
  return {
    required: required ? "Email address is required" : false,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "Invalid email address",
    },
  };
};

export const nameFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    pattern: {
      value: /^[A-Za-z\s]*$/,
      message: "Invalid name",
    },
    minLength: {
      value: 2,
      message: "Must be more than 1 character",
    },
  };
};

export const handleFormValidation = (required: boolean) => {
  return {
    required: required ? "Username is required" : false,
    pattern: {
      value: /^[a-z0-9._]+$/i,
      message: "Invalid username",
    },
  };
};

export const textFormValidation = (required: boolean, minLength?: number) => {
  return {
    required: required ? "This field is required" : false,
    minLength: minLength
      ? {
          value: minLength + 1,
          message: `Must be more than ${minLength} character(s)`,
        }
      : {
          value: 2,
          message: "Must be more than 1 character",
        },
  };
};

export const numberFormValidation = (
  required: boolean,
  minLength: number,
  maxLength: number,
  min?: number,
  max?: number
) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: minLength,
      message: `Minimum characters is ${minLength}`,
    },
    maxLength: {
      value: maxLength,
      message: `Maximum characters is ${maxLength}`,
    },
    min: {
      value: min || 0,
      message: `Minimum value is ${min || 0}`,
    },
    max: {
      value: max || 10000000000000000000,
      message: `Maximum value is ${max || 10000000000000000000}`,
    },
  };
};

export const phoneNumberValidation = (
  required: boolean,
  minLength: number,
  maxLength: number,
  min?: number,
  max?: number
) => {
  return {
    required: required ? "Phone number is required" : false,
    minLength: {
      value: minLength,
      message: `Minimum characters is ${minLength}`,
    },
    maxLength: {
      value: maxLength,
      message: `Maximum characters is ${maxLength}`,
    },
    min: {
      value: min || 0,
      message: `Minimum value is ${min || 0}`,
    },
    max: {
      value: max || 10000000000000000000,
      message: `Maximum value is ${max || 10000000000000000000}`,
    },
  };
};

export const usPhoneNumberValidation = (required: boolean) => {
  return {
    required: required ? "Phone number is required" : false,
    pattern: {
      value: /^\D*(\d\D*){11,}$/,
      message: "Please provide a valid phone number",
    },
  };
};

export const passwordFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
    minLength: {
      value: 8,
      message: "Must be atleast 8 characters",
    },
  };
};

export const basicFormValidation = (required: boolean) => {
  return {
    required: required ? "This field is required" : false,
  };
};

export const getHostInfo = (url: string) => {
  const parseResult = parseDomain(url);

  if (parseResult.type === ParseResultType.Listed) {
    const { domain, topLevelDomains } = parseResult;
    return {
      domain: domain,
      topLevelDomains: topLevelDomains,
    };
  } else {
    console.log(parseResult.type);
    throw new Error(`Url provided could not be parsed: ${url}.`);
  }
};

export function setCookie(name: string, value: string, days: number): void {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = `${name}=${value};${expires};path=/`;
}

export function getCookie(name: string): string | null {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function deleteCookie(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
