export const NoCreators = () => {
  return (
    <svg
      width="110"
      height="87"
      viewBox="0 0 110 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="self-checkout/rafiki">
        <g id="background-complete">
          <path
            id="Vector"
            d="M110 76.9902H0V77.0456H110V76.9902Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_2"
            d="M98.9811 80.5586H95.7031V80.614H98.9811V80.5586Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_3"
            d="M72.8649 81.1602H70.9531V81.2156H72.8649V81.1602Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_4"
            d="M94.7718 78.502H87.25V78.5574H94.7718V78.502Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_5"
            d="M24.119 79.6445H14.6172V79.6999H24.119V79.6445Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_6"
            d="M29.4979 79.6445H26.0703V79.6999H29.4979V79.6445Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_7"
            d="M56.0549 78.9004H35.4453V78.9558H56.0549V78.9004Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_8"
            d="M52.1401 67.1068H9.66025C9.32747 67.1062 9.00851 66.9726 8.7734 66.7353C8.5383 66.4981 8.40625 66.1765 8.40625 65.8413V5.68603C8.40915 5.35271 8.54247 5.03401 8.77727 4.79914C9.01206 4.56427 9.32936 4.43221 9.66025 4.43164H52.1401C52.4732 4.43164 52.7927 4.56497 53.0283 4.80229C53.2639 5.03961 53.3963 5.36149 53.3963 5.69711V65.8413C53.3963 66.1769 53.2639 66.4988 53.0283 66.7361C52.7927 66.9734 52.4732 67.1068 52.1401 67.1068ZM9.66025 4.47597C9.34205 4.47655 9.03709 4.6043 8.8123 4.83117C8.5875 5.05803 8.46125 5.36548 8.46125 5.68603V65.8413C8.46125 66.1618 8.5875 66.4693 8.8123 66.6962C9.03709 66.923 9.34205 67.0508 9.66025 67.0514H52.1401C52.4585 67.0508 52.7636 66.9231 52.9888 66.6963C53.2139 66.4695 53.3407 66.1621 53.3413 65.8413V5.68603C53.3407 5.36528 53.2139 5.05784 52.9888 4.83103C52.7636 4.60423 52.4585 4.47655 52.1401 4.47597H9.66025Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_9"
            d="M99.7304 67.1068H57.2484C56.9154 67.1062 56.5962 66.9727 56.3608 66.7355C56.1253 66.4983 55.9928 66.1767 55.9922 65.8413V5.68603C55.9957 5.35251 56.1295 5.03381 56.3646 4.799C56.5998 4.5642 56.9173 4.43221 57.2484 4.43164H99.7304C100.061 4.43279 100.378 4.56511 100.612 4.79992C100.846 5.03472 100.979 5.3531 100.982 5.68603V65.8413C100.982 66.1762 100.85 66.4974 100.616 66.7346C100.381 66.9718 100.063 67.1056 99.7304 67.1068ZM57.2484 4.47597C56.93 4.47655 56.6248 4.60423 56.3997 4.83103C56.1745 5.05784 56.0478 5.36528 56.0472 5.68603V65.8413C56.0478 66.1621 56.1745 66.4695 56.3997 66.6963C56.6248 66.9231 56.93 67.0508 57.2484 67.0514H99.7304C100.049 67.0508 100.354 66.9231 100.579 66.6963C100.804 66.4695 100.931 66.1621 100.932 65.8413V5.68603C100.931 5.36528 100.804 5.05784 100.579 4.83103C100.354 4.60423 100.049 4.47655 99.7304 4.47597H57.2484Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_10"
            d="M37.2337 17.9531H16.4062V76.9936H37.2337V17.9531Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_11"
            d="M37.2387 76.9902H92.4609L92.4609 17.9497L37.2387 17.9497L37.2387 76.9902Z"
            fill="#F5F5F5"
          />
          <path
            id="Vector_12"
            d="M39.4383 25.3379H14.2109V26.8161H39.4383V25.3379Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_13"
            d="M39.434 26.8164L94.6562 26.8164V25.3382L39.434 25.3382V26.8164Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_14"
            d="M39.4383 34.2012H14.2109V35.6794H39.4383V34.2012Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_15"
            d="M39.434 35.6816L94.6562 35.6816V34.2034L39.434 34.2034V35.6816Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_16"
            d="M39.4383 43.0684H14.2109V44.5466H39.4383V43.0684Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_17"
            d="M39.434 44.5449L94.6562 44.5449V43.0667L39.434 43.0667V44.5449Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_18"
            d="M39.4383 51.9336H14.2109V53.4118H39.4383V51.9336Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_19"
            d="M39.434 53.4121L94.6562 53.4121V51.9339L39.434 51.9339V53.4121Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_20"
            d="M39.4383 60.7988H14.2109V62.2771H39.4383V60.7988Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_21"
            d="M39.434 62.2773L94.6562 62.2773V60.7991L39.434 60.7991V62.2773Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_22"
            d="M39.4383 69.6621H14.2109V71.1403H39.4383V69.6621Z"
            fill="#EBEBEB"
          />
          <path
            id="Vector_23"
            d="M39.434 71.1406H94.6562V69.6624H39.434V71.1406Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_24"
            d="M53.93 0H22.25V15.7353H53.93V0Z"
            fill="#F5F5F5"
          />
          <path
            id="Vector_25"
            d="M39.4306 15.7344H36.7422V17.9506H39.4306V15.7344Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_26"
            d="M53.93 14.0254H22.25V15.7363H53.93V14.0254Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_27"
            d="M53.93 0H22.25V1.71093H53.93V0Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_28"
            d="M82.7527 6.56055H67.0469V16.5469H82.7527V6.56055Z"
            fill="#F5F5F5"
          />
          <path
            id="Vector_29"
            d="M75.7463 16.5449H74.0391V17.9522H75.7463V16.5449Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_30"
            d="M82.7527 15.4609H67.0469V16.5469H82.7527V15.4609Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_31"
            d="M82.7527 6.56055H67.0469V7.6465H82.7527V6.56055Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_32"
            d="M84.0159 19.7422H78.4609V25.3382H84.0159V19.7422Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_33"
            d="M84.0182 25.3398H89.3906V19.7439H84.0182V25.3398Z"
            fill="white"
          />
          <path
            id="Vector_34"
            d="M77.4144 19.7422H71.8594V25.3382H77.4144V19.7422Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_35"
            d="M77.4167 25.3398H82.7891V19.7439H77.4167V25.3398Z"
            fill="white"
          />
          <path
            id="Vector_36"
            d="M70.8128 19.7422H65.2578V25.3382H70.8128V19.7422Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_37"
            d="M70.8151 25.3398H76.1875V19.7439H70.8151V25.3398Z"
            fill="white"
          />
          <path
            id="Vector_38"
            d="M64.2191 19.7422H58.6641V25.3382H64.2191V19.7422Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_39"
            d="M64.2135 25.3398H69.5859V19.7439H64.2135V25.3398Z"
            fill="white"
          />
          <path
            id="Vector_40"
            d="M20.3521 34.207H22.6797V28.3207H20.3521V34.207Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_41"
            d="M20.3522 28.3164H18.1016V34.2027H20.3522V28.3164Z"
            fill="white"
          />
          <path
            id="Vector_42"
            d="M23.1177 34.207H25.4453V28.3207H23.1177V34.207Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_43"
            d="M23.1178 28.3164H20.8672V34.2027H23.1178V28.3164Z"
            fill="white"
          />
          <path
            id="Vector_44"
            d="M25.8833 34.207H28.2109V28.3207H25.8833V34.207Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_45"
            d="M25.8834 28.3164H23.6328V34.2027H25.8834V28.3164Z"
            fill="white"
          />
          <path
            id="Vector_46"
            d="M28.649 34.207H30.9766V28.3207H28.649V34.207Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_47"
            d="M28.649 28.3164H26.3984V34.2027H28.649V28.3164Z"
            fill="white"
          />
          <path
            id="Vector_48"
            d="M22.2578 60.7988H25.3906V56.834H22.2578V60.7988Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_49"
            d="M22.2638 56.834H19.2344V60.7988H22.2638V56.834Z"
            fill="white"
          />
          <path
            id="Vector_50"
            d="M25.9766 60.7988H29.1094V56.834H25.9766V60.7988Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_51"
            d="M25.9825 56.834H22.9531V60.7988H25.9825V56.834Z"
            fill="white"
          />
          <path
            id="Vector_52"
            d="M29.6953 60.7988H32.8281V56.834H29.6953V60.7988Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_53"
            d="M29.7013 56.834H26.6719V60.7988H29.7013V56.834Z"
            fill="white"
          />
          <path
            id="Vector_54"
            d="M33.4219 60.7988H36.5547V56.834H33.4219V60.7988Z"
            fill="#F0F0F0"
          />
          <path
            id="Vector_55"
            d="M33.42 56.834H30.3906V60.7988H33.42V56.834Z"
            fill="white"
          />
          <path
            id="Vector_56"
            d="M85.0544 37.4707H81.9766V43.0667H85.0544V37.4707Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_57"
            d="M85.0547 43.0703H88.0312V37.4743H85.0547V43.0703Z"
            fill="white"
          />
          <path
            id="Vector_58"
            d="M81.3981 37.4707H78.3203V43.0667H81.3981V37.4707Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_59"
            d="M81.3906 43.0703H84.3672V37.4743H81.3906V43.0703Z"
            fill="white"
          />
          <path
            id="Vector_60"
            d="M77.734 37.4707H74.6562V43.0667H77.734V37.4707Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_61"
            d="M77.7343 43.0703H80.7109V37.4743H77.7343V43.0703Z"
            fill="white"
          />
          <path
            id="Vector_62"
            d="M74.0778 37.4707H71V43.0667H74.0778V37.4707Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_63"
            d="M74.0781 43.0703H77.0547V37.4743H74.0781V43.0703Z"
            fill="white"
          />
          <path
            id="Vector_64"
            d="M66.07 37.4707H62.9922V43.0667H66.07V37.4707Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_65"
            d="M66.0781 43.0703H69.0547V37.4743H66.0781V43.0703Z"
            fill="white"
          />
          <path
            id="Vector_66"
            d="M62.4137 37.4707H59.3359V43.0667H62.4137V37.4707Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_67"
            d="M62.414 43.0703H65.3906V37.4743H62.414V43.0703Z"
            fill="white"
          />
          <path
            id="Vector_68"
            d="M58.7497 37.4707H55.6719V43.0667H58.7497V37.4707Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_69"
            d="M58.7578 43.0703H61.7344V37.4743H58.7578V43.0703Z"
            fill="white"
          />
          <path
            id="Vector_70"
            d="M55.0934 37.4707H52.0156V43.0667H55.0934V37.4707Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_71"
            d="M55.1015 43.0703H58.0781V37.4743H55.1015V43.0703Z"
            fill="white"
          />
          <path
            id="Vector_72"
            d="M80.0333 56.8359H72.5312V60.7875H80.0333V56.8359Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_73"
            d="M80.0435 60.7832H87.2969V56.8317H80.0435V60.7832Z"
            fill="white"
          />
          <path
            id="Vector_74"
            d="M71.1192 56.8359H63.6172V60.7875H71.1192V56.8359Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_75"
            d="M71.1294 60.7832H78.3828V56.8317H71.1294V60.7832Z"
            fill="white"
          />
          <path
            id="Vector_76"
            d="M62.2051 56.8359H54.7031V60.7875H62.2051V56.8359Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_77"
            d="M62.1997 60.7832H69.4531V56.8317H62.1997V60.7832Z"
            fill="white"
          />
          <path
            id="Vector_78"
            d="M53.2989 56.8359H45.7969V60.7875H53.2989V56.8359Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_79"
            d="M53.2935 60.7832H60.5469V56.8317H53.2935V60.7832Z"
            fill="white"
          />
          <path
            id="Vector_80"
            d="M45.9203 34.2033C47.2872 34.2033 48.3953 33.0871 48.3953 31.7101C48.3953 30.3331 47.2872 29.2168 45.9203 29.2168C44.5534 29.2168 43.4453 30.3331 43.4453 31.7101C43.4453 33.0871 44.5534 34.2033 45.9203 34.2033Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_81"
            d="M52.5219 34.2033C53.8888 34.2033 54.9969 33.0871 54.9969 31.7101C54.9969 30.3331 53.8888 29.2168 52.5219 29.2168C51.155 29.2168 50.0469 30.3331 50.0469 31.7101C50.0469 33.0871 51.155 34.2033 52.5219 34.2033Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_82"
            d="M59.1156 34.2033C60.4825 34.2033 61.5906 33.0871 61.5906 31.7101C61.5906 30.3331 60.4825 29.2168 59.1156 29.2168C57.7487 29.2168 56.6406 30.3331 56.6406 31.7101C56.6406 33.0871 57.7487 34.2033 59.1156 34.2033Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_83"
            d="M65.7172 34.2033C67.0841 34.2033 68.1922 33.0871 68.1922 31.7101C68.1922 30.3331 67.0841 29.2168 65.7172 29.2168C64.3503 29.2168 63.2422 30.3331 63.2422 31.7101C63.2422 33.0871 64.3503 34.2033 65.7172 34.2033Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_84"
            d="M19.7762 51.8916C20.6536 51.6829 21.1971 50.7973 20.99 49.9133C20.7829 49.0294 19.9037 48.482 19.0262 48.6906C18.1488 48.8992 17.6054 49.7849 17.8125 50.6688C18.0196 51.5528 18.8988 52.1002 19.7762 51.8916Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_85"
            d="M24.12 51.8916C24.9974 51.6829 25.5408 50.7973 25.3337 49.9133C25.1266 49.0294 24.2474 48.482 23.37 48.6906C22.4926 48.8992 21.9491 49.7849 22.1562 50.6688C22.3633 51.5528 23.2425 52.1002 24.12 51.8916Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_86"
            d="M29.6097 50.919C29.9547 50.08 29.5592 49.118 28.7263 48.7705C27.8934 48.4229 26.9385 48.8213 26.5934 49.6604C26.2484 50.4995 26.644 51.4614 27.4769 51.809C28.3098 52.1565 29.2647 51.7581 29.6097 50.919Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_87"
            d="M33.6191 51.4538C34.2566 50.8116 34.2566 49.7704 33.6191 49.1282C32.9816 48.486 31.948 48.486 31.3105 49.1282C30.673 49.7704 30.673 50.8116 31.3105 51.4538C31.948 52.096 32.9816 52.096 33.6191 51.4538Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_88"
            d="M74.7989 68.612C75.1574 67.7401 74.7464 66.7406 73.8809 66.3794C73.0154 66.0183 72.0232 66.4323 71.6647 67.3042C71.3062 68.176 71.7172 69.1756 72.5827 69.5367C73.4482 69.8978 74.4404 69.4838 74.7989 68.612Z"
            fill="white"
          />
          <path
            id="Vector_89"
            d="M77.9099 69.3477C78.8186 69.1306 79.3806 68.2126 79.1651 67.2972C78.9497 66.3818 78.0384 65.8157 77.1297 66.0328C76.221 66.2498 75.659 67.1678 75.8745 68.0832C76.09 68.9986 77.0013 69.5647 77.9099 69.3477Z"
            fill="white"
          />
          <path
            id="Vector_90"
            d="M83.838 68.61C84.1965 67.7381 83.7855 66.7386 82.92 66.3775C82.0545 66.0163 81.0623 66.4303 80.7038 67.3022C80.3453 68.1741 80.7563 69.1736 81.6218 69.5348C82.4872 69.8959 83.4795 69.4819 83.838 68.61Z"
            fill="white"
          />
          <path
            id="Vector_91"
            d="M88.3614 68.61C88.7199 67.7381 88.3089 66.7386 87.4434 66.3775C86.5779 66.0163 85.5857 66.4303 85.2272 67.3022C84.8687 68.1741 85.2797 69.1736 86.1452 69.5348C87.0107 69.8959 88.0029 69.4819 88.3614 68.61Z"
            fill="white"
          />
          <path
            id="Vector_92"
            d="M76.7622 66.7805C77.4246 66.1132 77.4247 65.0313 76.7622 64.364C76.0998 63.6967 75.0259 63.6967 74.3635 64.364C73.701 65.0313 73.701 66.1132 74.3635 66.7805C75.0259 67.4478 76.0998 67.4478 76.7622 66.7805Z"
            fill="white"
          />
          <path
            id="Vector_93"
            d="M81.6426 66.2252C82.0011 65.3534 81.5902 64.3538 80.7247 63.9927C79.8592 63.6316 78.867 64.0456 78.5085 64.9174C78.15 65.7893 78.561 66.7889 79.4265 67.15C80.2919 67.5111 81.2842 67.0971 81.6426 66.2252Z"
            fill="white"
          />
          <path
            id="Vector_94"
            d="M84.7303 66.9688C85.6389 66.7517 86.2009 65.8337 85.9854 64.9183C85.77 64.0029 84.8587 63.4368 83.95 63.6539C83.0413 63.8709 82.4794 64.7889 82.6948 65.7043C82.9103 66.6197 83.8216 67.1858 84.7303 66.9688Z"
            fill="white"
          />
          <path
            id="Vector_95"
            d="M52.4888 69.1653C53.1512 68.498 53.1512 67.4161 52.4888 66.7488C51.8264 66.0815 50.7524 66.0815 50.09 66.7488C49.4276 67.4161 49.4276 68.498 50.09 69.1653C50.7524 69.8326 51.8264 69.8326 52.4888 69.1653Z"
            fill="white"
          />
          <path
            id="Vector_96"
            d="M57.3692 68.612C57.7277 67.7401 57.3167 66.7406 56.4512 66.3794C55.5858 66.0183 54.5935 66.4323 54.235 67.3042C53.8766 68.176 54.2875 69.1756 55.153 69.5367C56.0185 69.8978 57.0107 69.4838 57.3692 68.612Z"
            fill="white"
          />
          <path
            id="Vector_97"
            d="M60.7192 69.6249C61.6309 69.4082 62.1956 68.4879 61.9804 67.5694C61.7652 66.6509 60.8516 66.0821 59.9399 66.2989C59.0282 66.5157 58.4635 67.436 58.6787 68.3544C58.8939 69.2729 59.8074 69.8417 60.7192 69.6249Z"
            fill="white"
          />
          <path
            id="Vector_98"
            d="M66.4161 68.61C66.7746 67.7381 66.3636 66.7386 65.4981 66.3775C64.6326 66.0163 63.6404 66.4303 63.2819 67.3022C62.9234 68.1741 63.3344 69.1736 64.1999 69.5348C65.0654 69.8959 66.0576 69.4819 66.4161 68.61Z"
            fill="white"
          />
          <path
            id="Vector_99"
            d="M55.1739 66.2252C55.5324 65.3534 55.1214 64.3538 54.2559 63.9927C53.3904 63.6316 52.3982 64.0456 52.0397 64.9174C51.6812 65.7893 52.0922 66.7889 52.9577 67.15C53.8232 67.5111 54.8154 67.0971 55.1739 66.2252Z"
            fill="white"
          />
          <path
            id="Vector_100"
            d="M59.3247 66.7805C59.9872 66.1132 59.9872 65.0313 59.3247 64.364C58.6623 63.6967 57.5884 63.6967 56.926 64.364C56.2636 65.0313 56.2635 66.1132 56.926 66.7805C57.5884 67.4478 58.6623 67.4478 59.3247 66.7805Z"
            fill="white"
          />
          <path
            id="Vector_101"
            d="M64.2208 66.2252C64.5793 65.3534 64.1683 64.3538 63.3028 63.9927C62.4373 63.6316 61.4451 64.0456 61.0866 64.9174C60.7281 65.7893 61.1391 66.7889 62.0046 67.15C62.8701 67.5111 63.8623 67.0971 64.2208 66.2252Z"
            fill="white"
          />
          <path
            id="Vector_102"
            d="M82.9409 47.4707L84.1839 51.932H77.6719L82.9409 47.4707Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_103"
            d="M82.9279 47.4707L80.6641 51.932H87.5347L82.9279 47.4707Z"
            fill="white"
          />
          <path
            id="Vector_104"
            d="M72.6306 47.4707L73.8736 51.932H67.3594L72.6306 47.4707Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_105"
            d="M72.6388 47.4707L70.375 51.932H77.2456L72.6388 47.4707Z"
            fill="white"
          />
          <path
            id="Vector_106"
            d="M62.3393 47.4707L63.5845 51.932H57.0703L62.3393 47.4707Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_107"
            d="M62.3341 47.4707L60.0703 51.932H66.9431L62.3341 47.4707Z"
            fill="white"
          />
          <path
            id="Vector_108"
            d="M19.9508 38.7344L19.2578 43.0671H22.89L19.9508 38.7344Z"
            fill="#F5F5F5"
          />
          <path
            id="Vector_109"
            d="M19.9602 38.7344L21.223 43.0671H17.3906L19.9602 38.7344Z"
            fill="white"
          />
          <path
            id="Vector_110"
            d="M25.693 38.7344L25 43.0671H28.6322L25.693 38.7344Z"
            fill="#F5F5F5"
          />
          <path
            id="Vector_111"
            d="M25.6946 38.7344L26.9574 43.0671H23.125L25.6946 38.7344Z"
            fill="white"
          />
          <path
            id="Vector_112"
            d="M31.4452 38.7344L30.75 43.0671H34.3844L31.4452 38.7344Z"
            fill="#F5F5F5"
          />
          <path
            id="Vector_113"
            d="M31.4446 38.7344L32.7074 43.0671H28.875L31.4446 38.7344Z"
            fill="white"
          />
          <path
            id="Vector_114"
            d="M52.0346 47.4707L53.2776 51.932H46.7656L52.0346 47.4707Z"
            fill="#E0E0E0"
          />
          <path
            id="Vector_115"
            d="M52.0294 47.4707L49.7656 51.932H56.6384L52.0294 47.4707Z"
            fill="white"
          />
        </g>
        <g id="Shadow">
          <path
            id="path"
            d="M54.9995 87.0019C78.5577 87.0019 97.6554 85.8787 97.6554 84.4931C97.6554 83.1076 78.5577 81.9844 54.9995 81.9844C31.4414 81.9844 12.3438 83.1076 12.3438 84.4931C12.3438 85.8787 31.4414 87.0019 54.9995 87.0019Z"
            fill="#F5F5F5"
          />
        </g>
        <g id="cash-register">
          <path
            id="Vector_116"
            d="M64.25 55.5605H82.4638C82.8355 55.5605 83.1919 55.7093 83.4547 55.974C83.7176 56.2388 83.8652 56.5979 83.8652 56.9723C83.8652 57.3467 83.7176 57.7058 83.4547 57.9705C83.1919 58.2353 82.8355 58.384 82.4638 58.384H64.25V55.5605Z"
            fill="#BE9137"
          />
          <path
            id="Vector_117"
            opacity="0.6"
            d="M64.25 55.5605H82.4638C82.8355 55.5605 83.1919 55.7093 83.4547 55.974C83.7176 56.2388 83.8652 56.5979 83.8652 56.9723C83.8652 57.3467 83.7176 57.7058 83.4547 57.9705C83.1919 58.2353 82.8355 58.384 82.4638 58.384H64.25V55.5605Z"
            fill="white"
          />
          <path
            id="Vector_118"
            d="M82.7225 47.2422C82.9896 46.1022 82.2887 44.9599 81.157 44.6908C80.0253 44.4217 78.8914 45.1278 78.6243 46.2678C78.3572 47.4078 79.058 48.5502 80.1897 48.8192C81.3214 49.0883 82.4554 48.3822 82.7225 47.2422Z"
            fill="#BE9137"
          />
          <path
            id="Vector_119"
            opacity="0.8"
            d="M82.7225 47.2422C82.9896 46.1022 82.2887 44.9599 81.157 44.6908C80.0253 44.4217 78.8914 45.1278 78.6243 46.2678C78.3572 47.4078 79.058 48.5502 80.1897 48.8192C81.3214 49.0883 82.4554 48.3822 82.7225 47.2422Z"
            fill="white"
          />
          <path
            id="Vector_120"
            d="M66.1875 47.041H83.5917L81.9241 55.5602H67.8529L66.1875 47.041Z"
            fill="#002D2B"
          />
          <path
            id="Vector_121"
            d="M65.7009 46.2246H84.0753C84.1157 46.2244 84.1556 46.2337 84.1919 46.2517C84.2281 46.2698 84.2597 46.2961 84.284 46.3286C84.3084 46.3611 84.3249 46.3989 84.3322 46.4389C84.3395 46.479 84.3374 46.5202 84.3261 46.5593L84.1061 47.315C84.09 47.3694 84.0569 47.4172 84.0117 47.4511C83.9665 47.485 83.9116 47.5034 83.8553 47.5034H65.9187C65.8623 47.5034 65.8075 47.485 65.7623 47.4511C65.7171 47.4172 65.684 47.3694 65.6679 47.315L65.4479 46.5593C65.4365 46.52 65.4344 46.4786 65.4419 46.4384C65.4493 46.3981 65.466 46.3602 65.4906 46.3277C65.5152 46.2951 65.547 46.2688 65.5835 46.251C65.6201 46.2331 65.6603 46.224 65.7009 46.2246Z"
            fill="#BE9137"
          />
          <path
            id="Vector_122"
            opacity="0.6"
            d="M65.7009 46.2246H84.0753C84.1157 46.2244 84.1556 46.2337 84.1919 46.2517C84.2281 46.2698 84.2597 46.2961 84.284 46.3286C84.3084 46.3611 84.3249 46.3989 84.3322 46.4389C84.3395 46.479 84.3374 46.5202 84.3261 46.5593L84.1061 47.315C84.09 47.3694 84.0569 47.4172 84.0117 47.4511C83.9665 47.485 83.9116 47.5034 83.8553 47.5034H65.9187C65.8623 47.5034 65.8075 47.485 65.7623 47.4511C65.7171 47.4172 65.684 47.3694 65.6679 47.315L65.4479 46.5593C65.4365 46.52 65.4344 46.4786 65.4419 46.4384C65.4493 46.3981 65.466 46.3602 65.4906 46.3277C65.5152 46.2951 65.547 46.2688 65.5835 46.251C65.6201 46.2331 65.6603 46.224 65.7009 46.2246Z"
            fill="white"
          />
          <path
            id="Vector_123"
            d="M76.2506 45.2129H67.8906V46.2235H76.2506V45.2129Z"
            fill="#BE9137"
          />
          <path
            id="Vector_124"
            opacity="0.2"
            d="M76.2506 45.2129H67.8906V46.2235H76.2506V45.2129Z"
            fill="black"
          />
          <path
            id="Vector_125"
            d="M72.6873 48.0679C72.6678 48.1284 72.6279 48.1802 72.5745 48.2142C72.521 48.2482 72.4574 48.2623 72.3947 48.254C71.7347 48.141 71.4092 47.536 71.649 46.8068L73.332 41.6895C73.5982 40.8806 74.4671 40.2246 75.2679 40.2246H78.249C79.0498 40.2246 79.4853 40.8895 79.2213 41.6895L77.7275 46.224H77.2281L78.7197 41.6895C78.8957 41.1599 78.6098 40.7277 78.084 40.7277H75.1029C74.8212 40.7463 74.5509 40.8472 74.3251 41.018C74.0993 41.1888 73.9277 41.4222 73.8313 41.6895L72.1483 46.8068C72.0053 47.239 72.1703 47.6069 72.5201 47.7266C72.5534 47.7378 72.5842 47.7556 72.6107 47.7788C72.6371 47.8021 72.6587 47.8304 72.6743 47.8622C72.6898 47.8939 72.699 47.9284 72.7012 47.9637C72.7035 47.999 72.6987 48.0344 72.6873 48.0679Z"
            fill="#BE9137"
          />
          <path
            id="Vector_126"
            d="M67.9421 45.5723H39.6061C38.5551 45.5723 37.7031 46.4306 37.7031 47.4893V82.5767C37.7031 83.6355 38.5551 84.4938 39.6061 84.4938H67.9421C68.9931 84.4938 69.8451 83.6355 69.8451 82.5767V47.4893C69.8451 46.4306 68.9931 45.5723 67.9421 45.5723Z"
            fill="#BE9137"
          />
          <path
            id="Vector_127"
            d="M45.528 60.1113H19.128C18.7561 60.1119 18.3997 60.2611 18.1369 60.5262C17.8742 60.7914 17.7266 61.1507 17.7266 61.5253C17.7257 61.7112 17.7613 61.8955 17.8314 62.0676C17.9014 62.2396 18.0045 62.396 18.1347 62.5278C18.2649 62.6596 18.4197 62.7642 18.5902 62.8356C18.7606 62.9069 18.9434 62.9437 19.128 62.9437H45.528V60.1113Z"
            fill="#BE9137"
          />
          <path
            id="Vector_128"
            opacity="0.3"
            d="M45.5324 63.7181L37.7422 67.2708V62.9336H45.5324V63.7181Z"
            fill="#002D2B"
          />
          <path
            id="Vector_129"
            opacity="0.7"
            d="M45.5207 60.1113H28.7061C28.3342 60.1119 27.9778 60.2611 27.715 60.5262C27.4523 60.7914 27.3047 61.1507 27.3047 61.5253C27.3053 61.8995 27.4531 62.2583 27.7158 62.5229C27.9785 62.7875 28.3346 62.9364 28.7061 62.937H45.5207V60.1113Z"
            fill="white"
          />
          <path
            id="Vector_130"
            opacity="0.7"
            d="M67.9376 45.5723H48.3796C47.3286 45.5723 46.4766 46.4306 46.4766 47.4893V82.5767C46.4766 83.6355 47.3286 84.4938 48.3796 84.4938H67.9376C68.9886 84.4938 69.8406 83.6355 69.8406 82.5767V47.4893C69.8406 46.4306 68.9886 45.5723 67.9376 45.5723Z"
            fill="white"
          />
          <path
            id="Vector_131"
            d="M30.5432 62.9355H28.1562V84.4929H30.5432V62.9355Z"
            fill="#BE9137"
          />
          <path
            id="Vector_132"
            opacity="0.7"
            d="M30.5432 62.9355H28.1562V84.4929H30.5432V62.9355Z"
            fill="white"
          />
          <path
            id="Vector_133"
            opacity="0.3"
            d="M30.5432 63.8711L28.1562 64.3919V62.9336H30.5432V63.8711Z"
            fill="#BE9137"
          />
          <path
            id="Vector_134"
            opacity="0.3"
            d="M67.1167 50.1719H49.2153C48.9152 50.1719 48.6719 50.417 48.6719 50.7193V56.8782C48.6719 57.1805 48.9152 57.4256 49.2153 57.4256H67.1167C67.4168 57.4256 67.6601 57.1805 67.6601 56.8782V50.7193C67.6601 50.417 67.4168 50.1719 67.1167 50.1719Z"
            fill="#BE9137"
          />
          <path
            id="Vector_135"
            d="M35.0948 41.7578H22.0488L20.7266 60.1127H36.417L35.0948 41.7578Z"
            fill="#BE9137"
          />
          <path
            id="Vector_136"
            opacity="0.3"
            d="M23.7802 41.7578H22.0488L20.7266 60.1127H24.2378L23.7802 41.7578Z"
            fill="#002D2B"
          />
          <path
            id="Vector_137"
            d="M29.3705 43.9959C28.1517 43.9959 27.0605 43.1338 26.8779 41.9858C26.8329 41.7079 26.8457 41.4235 26.9154 41.1508C26.985 40.878 27.1101 40.6228 27.2827 40.4012C27.4831 40.1508 27.7323 39.9443 28.015 39.7945C28.2976 39.6448 28.6076 39.5548 28.9261 39.5302C30.2351 39.4106 31.4583 40.3081 31.6519 41.5248C31.6966 41.8033 31.6835 42.0881 31.6134 42.3612C31.5434 42.6343 31.4179 42.8899 31.2449 43.1117C31.045 43.3622 30.7962 43.5688 30.5139 43.7186C30.2316 43.8684 29.9218 43.9583 29.6037 43.9827C29.5245 43.9915 29.4475 43.9959 29.3705 43.9959ZM29.1615 39.9092C29.0955 39.9092 29.0273 39.9092 28.9613 39.9092C28.6957 39.9294 28.437 40.0039 28.201 40.1281C27.965 40.2523 27.7566 40.4237 27.5885 40.6317C27.4486 40.8115 27.3473 41.0186 27.2909 41.2398C27.2345 41.4611 27.2242 41.6917 27.2607 41.9171C27.4213 42.9388 28.4575 43.6901 29.5685 43.5882C29.834 43.568 30.0927 43.4935 30.3287 43.3693C30.5647 43.2451 30.7732 43.0737 30.9413 42.8657C31.0811 42.6858 31.1824 42.4788 31.2388 42.2575C31.2952 42.0363 31.3055 41.8057 31.2691 41.5803C31.1173 40.6295 30.1933 39.9092 29.1615 39.9092Z"
            fill="#BE9137"
          />
          <path
            id="Vector_138"
            d="M28.3843 43.9967C28.3073 43.9967 28.2303 43.9967 28.1511 43.9967C27.833 43.9724 27.5231 43.8825 27.2408 43.7327C26.9585 43.5829 26.7098 43.3763 26.5099 43.1257C26.3367 42.9041 26.2112 42.6485 26.1415 42.3753C26.0718 42.1021 26.0594 41.8172 26.1051 41.5389C26.2965 40.32 27.5197 39.4224 28.8287 39.5443C29.1472 39.5686 29.4573 39.6584 29.74 39.8082C30.0227 39.958 30.2718 40.1646 30.4721 40.4153C30.6451 40.6366 30.7705 40.8918 30.8402 41.1646C30.9099 41.4374 30.9224 41.7219 30.8769 41.9999C30.6877 43.1346 29.5943 43.9967 28.3843 43.9967ZM28.6043 39.91C27.5703 39.91 26.6463 40.6302 26.4945 41.5899C26.458 41.8153 26.4683 42.0459 26.5248 42.2672C26.5812 42.4884 26.6825 42.6955 26.8223 42.8753C26.9904 43.0833 27.1988 43.2547 27.4349 43.3789C27.6709 43.5031 27.9296 43.5776 28.1951 43.5978C29.3061 43.6975 30.3423 42.9484 30.5051 41.9267C30.5418 41.7011 30.5314 41.4703 30.4746 41.249C30.4178 41.0277 30.3158 40.8207 30.1751 40.6413C30.0074 40.4333 29.7994 40.262 29.5637 40.1378C29.3281 40.0135 29.0697 39.939 28.8045 39.9188C28.7187 39.9122 28.6527 39.91 28.5867 39.91H28.6043Z"
            fill="#BE9137"
          />
          <path
            id="Vector_139"
            d="M51.9267 16.748H48.0547V45.5746H51.9267V16.748Z"
            fill="#BE9137"
          />
          <path
            id="Vector_140"
            opacity="0.3"
            d="M51.9267 32.1816H48.0547V39.7146L51.9267 39.2337V32.1816Z"
            fill="#002D2B"
          />
          <path
            id="Vector_141"
            opacity="0.3"
            d="M51.9267 16.9453H48.0547V19.1372L51.9267 18.6562V16.9453Z"
            fill="#002D2B"
          />
          <path
            id="Vector_142"
            d="M39.8793 37.541L38.7991 21.7504C38.7901 21.6173 38.8083 21.4838 38.8526 21.3581C38.897 21.2324 38.9665 21.1173 39.0568 21.0198C39.1472 20.9223 39.2565 20.8446 39.3779 20.7915C39.4994 20.7383 39.6303 20.7109 39.7627 20.7109H63.8329C64.0775 20.7104 64.3131 20.8035 64.492 20.9714C64.6709 21.1393 64.7798 21.3694 64.7965 21.6152L65.8745 37.4058C65.8839 37.539 65.8659 37.6726 65.8217 37.7984C65.7775 37.9243 65.708 38.0396 65.6176 38.1371C65.5272 38.2346 65.4177 38.3124 65.2962 38.3654C65.1746 38.4184 65.0434 38.4456 64.9109 38.4453H40.8407C40.5964 38.4458 40.3611 38.3526 40.1825 38.1847C40.0039 38.0167 39.8955 37.7866 39.8793 37.541Z"
            fill="#002D2B"
          />
          <path
            id="Vector_143"
            d="M63.5599 21.4316H40.0837C40.009 21.4318 39.9351 21.4474 39.8666 21.4774C39.7981 21.5075 39.7365 21.5515 39.6855 21.6065C39.6346 21.6616 39.5954 21.7266 39.5704 21.7975C39.5455 21.8685 39.5352 21.9438 39.5403 22.0189L40.5787 37.2135C40.5882 37.3522 40.6495 37.4822 40.7504 37.5772C40.8512 37.6722 40.9841 37.7252 41.1221 37.7254H64.5983C64.6733 37.7256 64.7474 37.7102 64.8161 37.6803C64.8849 37.6503 64.9468 37.6064 64.998 37.5513C65.0492 37.4962 65.0886 37.4311 65.1137 37.36C65.1388 37.2889 65.1491 37.2134 65.1439 37.1381L64.1055 21.9414C64.0955 21.8026 64.0336 21.6729 63.9324 21.5783C63.8311 21.4837 63.698 21.4313 63.5599 21.4316Z"
            fill="#BE9137"
          />
          <path
            id="Vector_144"
            opacity="0.8"
            d="M63.5599 21.4316H40.0837C40.009 21.4318 39.9351 21.4474 39.8666 21.4774C39.7981 21.5075 39.7365 21.5515 39.6855 21.6065C39.6346 21.6616 39.5954 21.7266 39.5704 21.7975C39.5455 21.8685 39.5352 21.9438 39.5403 22.0189L40.5787 37.2135C40.5882 37.3522 40.6495 37.4822 40.7504 37.5772C40.8512 37.6722 40.9841 37.7252 41.1221 37.7254H64.5983C64.6733 37.7256 64.7474 37.7102 64.8161 37.6803C64.8849 37.6503 64.9468 37.6064 64.998 37.5513C65.0492 37.4962 65.0886 37.4311 65.1137 37.36C65.1388 37.2889 65.1491 37.2134 65.1439 37.1381L64.1055 21.9414C64.0955 21.8026 64.0336 21.6729 63.9324 21.5783C63.8311 21.4837 63.698 21.4313 63.5599 21.4316Z"
            fill="white"
          />
          <path
            id="Vector_145"
            d="M63.8005 45.043H43.1953V45.5704H63.8005V45.043Z"
            fill="#002D2B"
          />
          <path
            id="Vector_146"
            opacity="0.3"
            d="M58.0611 35.9465H42.5555L41.6953 23.3672H57.2009L58.0611 35.9465Z"
            fill="#BE9137"
          />
          <path
            id="Vector_147"
            opacity="0.3"
            d="M62.497 24.493H58.3192L58.2422 23.3672H62.42L62.497 24.493Z"
            fill="#BE9137"
          />
          <path
            id="Vector_148"
            opacity="0.3"
            d="M62.8097 29.0294H58.6319L58.4141 25.8203H62.5919L62.8097 29.0294Z"
            fill="#BE9137"
          />
          <path
            id="Vector_149"
            d="M53.7746 12.2148H45.9844V18.0724H53.7746V12.2148Z"
            fill="#BE9137"
          />
          <path
            id="Vector_150"
            opacity="0.3"
            d="M49.225 12.2148H45.9844V18.0724H49.225V12.2148Z"
            fill="#002D2B"
          />
        </g>
        <g id="Character">
          <path
            id="Vector_151"
            d="M70.5785 37.412L71.0075 36.8601C71.0873 36.7577 71.1902 36.6761 71.3077 36.6221C71.4253 36.5682 71.5539 36.5434 71.6829 36.5498L72.9061 36.6052C73.147 36.6173 73.3815 36.6869 73.5904 36.8083C73.7992 36.9297 73.9764 37.0994 74.1073 37.3034L74.2701 37.5604C73.6101 38.5245 72.2087 39.083 72.2087 39.083L71.8259 39.2869C71.7484 39.3311 71.6628 39.3594 71.5743 39.3701C71.4858 39.3808 71.3961 39.3737 71.3103 39.3491C71.2246 39.3246 71.1445 39.2831 71.0748 39.2272C71.005 39.1712 70.947 39.1019 70.9041 39.0232L70.4927 38.2608C70.4237 38.1262 70.395 37.9742 70.4102 37.8235C70.4255 37.6728 70.4839 37.5298 70.5785 37.412Z"
            fill="#FF8B7B"
          />
          <path
            id="Vector_152"
            d="M74.8319 26.3587C74.9595 26.5161 75.0519 26.6269 75.1443 26.7599C75.2367 26.8929 75.3291 27.0147 75.4193 27.1411C75.5997 27.3982 75.7647 27.6597 75.9297 27.9212C76.25 28.4512 76.5437 28.9971 76.8097 29.5568C76.9461 29.836 77.0693 30.1241 77.1947 30.4122C77.3201 30.7003 77.4257 30.9951 77.5269 31.2987C77.7378 31.9019 77.9084 32.5187 78.0373 33.1449L78.0615 33.2468C78.0771 33.3196 78.0844 33.394 78.0835 33.4684C78.0825 33.8645 78.0079 34.2569 77.8635 34.6253C77.7406 34.9307 77.5818 35.2201 77.3905 35.4874C77.0666 35.9265 76.683 36.3174 76.2509 36.6487C75.8645 36.9474 75.4538 37.2128 75.0233 37.4421C74.5994 37.669 74.1579 37.861 73.7033 38.0161C73.5255 38.0775 73.3323 38.0762 73.1554 38.0126C72.9784 37.9489 72.8282 37.8265 72.7293 37.6656C72.6303 37.5047 72.5886 37.3146 72.6109 37.1267C72.6331 36.9387 72.7181 36.7639 72.8519 36.631L72.9069 36.5778C73.1775 36.3052 73.4899 36.0104 73.7605 35.7223C74.0287 35.445 74.2814 35.1527 74.5173 34.8469C74.7229 34.5924 74.8965 34.3132 75.0343 34.0158C75.1443 33.7676 75.1311 33.5726 75.0981 33.5726L75.1443 33.8873C74.9793 33.4086 74.8033 32.9343 74.5899 32.4623C74.3765 31.9902 74.1499 31.527 73.8947 31.066C73.6395 30.605 73.3777 30.1529 73.0939 29.7097C72.9531 29.4881 72.8079 29.2664 72.6539 29.0448L72.4339 28.7257L72.2315 28.4376L72.1787 28.3622C71.9395 28.0099 71.844 27.5784 71.912 27.1571C71.98 26.7358 72.2063 26.3571 72.5439 26.0992C72.8816 25.8414 73.3048 25.7243 73.7258 25.7722C74.1468 25.8201 74.5334 26.0293 74.8055 26.3565L74.8319 26.3587Z"
            fill="#FF8B7B"
          />
          <path
            id="Vector_153"
            d="M71.8184 24.4801C71.198 25.8985 71.2376 27.6693 73.1384 30.3775C74.4012 30.5172 76.7376 28.8638 76.7376 28.8638C76.7376 28.8638 75.1338 24.3316 71.8184 24.4801Z"
            fill="#002D2B"
          />
          <path
            id="Vector_154"
            d="M70.7589 19.5488C70.3915 20.9229 69.9383 23.4405 70.8227 24.3558C70.8227 24.3558 69.5313 24.1608 66.7901 25.8695C65.9959 24.7215 66.5855 24.3558 66.5855 24.3558C68.1255 23.9902 68.1783 22.8555 67.9913 21.7872L70.7589 19.5488Z"
            fill="#FF8B7B"
          />
          <path
            id="Vector_155"
            d="M65.1797 25.3785C65.5867 24.492 66.5459 23.6476 67.2081 23.7739C67.8703 23.9002 68.2729 24.1086 68.6645 23.7739C69.0561 23.4393 70.0241 22.9517 70.5169 23.5035C71.2099 24.2748 72.2593 23.7251 71.8633 24.5695C71.4365 25.4871 65.1797 25.3785 65.1797 25.3785Z"
            fill="#BE9137"
          />
          <path
            id="Vector_156"
            d="M65.8037 17.0269C65.7773 17.2152 65.6585 17.3615 65.5397 17.3526C65.4209 17.3438 65.3461 17.1842 65.3725 16.9958C65.3989 16.8075 65.5177 16.6634 65.6365 16.6723C65.7553 16.6811 65.8323 16.8429 65.8037 17.0269Z"
            fill="#002D2B"
          />
          <path
            id="Vector_157"
            d="M65.6836 16.6833L65.2656 16.5215C65.2656 16.5215 65.4394 16.8894 65.6836 16.6833Z"
            fill="#002D2B"
          />
          <path
            id="Vector_158"
            d="M65.7681 17.3809C65.5439 17.7982 65.2673 18.1848 64.9453 18.5311C65.1543 18.7793 65.5789 18.704 65.5789 18.704L65.7681 17.3809Z"
            fill="#FF5652"
          />
          <path
            id="Vector_159"
            d="M65.5 15.534C65.4857 15.51 65.481 15.4815 65.4868 15.4542C65.4959 15.4267 65.5151 15.4038 65.5405 15.3902C65.5658 15.3766 65.5954 15.3733 65.6232 15.3811C65.7959 15.4199 65.9573 15.4985 66.0947 15.6108C66.2321 15.7231 66.3418 15.866 66.4152 16.0282C66.4206 16.0414 66.4232 16.0556 66.4226 16.0698C66.4221 16.0841 66.4184 16.0981 66.4119 16.1108C66.4054 16.1234 66.3962 16.1345 66.385 16.1432C66.3738 16.1519 66.3608 16.1581 66.347 16.1612C66.3189 16.1694 66.2888 16.167 66.2624 16.1543C66.236 16.1417 66.2152 16.1196 66.204 16.0925C66.1437 15.9651 66.0552 15.8533 65.9454 15.7658C65.8355 15.6782 65.7073 15.6171 65.5704 15.5872C65.5412 15.5788 65.5161 15.5598 65.5 15.534Z"
            fill="#002D2B"
          />
          <path
            id="Vector_160"
            d="M71.9236 16.7903C71.5078 19.0066 71.3802 19.9529 70.124 21.0167C68.2342 22.619 65.636 21.7724 65.3896 19.5097C65.1696 17.4752 65.8802 14.1907 68.1484 13.4571C68.6306 13.2792 69.1514 13.2352 69.6562 13.33C70.1611 13.4248 70.6313 13.6547 71.0175 13.9957C71.4036 14.3366 71.6916 14.7761 71.851 15.2678C72.0105 15.7596 72.0355 16.2855 71.9236 16.7903Z"
            fill="#FF8B7B"
          />
          <path
            id="Vector_161"
            d="M66.9976 17.507C66.2012 16.2503 65.3388 14.6813 65.88 13.5909C66.4212 12.5005 67.651 12.7598 67.651 12.7598C67.651 12.7598 70.0336 11.4123 72.1676 13.1676C74.9968 15.5013 71.963 18.0699 71.2128 20.295C70.3834 22.7439 66.1066 19.7786 66.9976 17.507Z"
            fill="#002D2B"
          />
          <path
            id="Vector_162"
            d="M79.1882 13.4341C78.0376 9.12347 72.9908 11.1048 71.5234 13.6557L72.1218 13.813C78.1432 9.89916 75.1776 15.5572 75.2282 17.8643C75.2788 20.1714 77.5492 21.592 77.5492 21.592C77.5492 21.592 80.3366 17.7424 79.1882 13.4341Z"
            fill="#002D2B"
          />
          <path
            id="Vector_163"
            d="M77.6704 21.0524C77.028 21.0524 76.1304 20.9305 75.5452 20.3343C75.0172 19.7958 74.8522 18.9736 75.059 17.8965C75.125 17.5552 75.2306 17.154 75.3406 16.7307C75.6882 15.401 76.1216 13.7654 75.4396 13.1848C74.96 12.7748 73.9502 12.9188 72.4366 13.6081C72.4302 13.6114 72.4231 13.6133 72.4159 13.6138C72.4086 13.6142 72.4014 13.6132 72.3946 13.6107C72.3878 13.6082 72.3815 13.6043 72.3763 13.5993C72.371 13.5942 72.3668 13.5882 72.364 13.5815C72.3611 13.5748 72.3595 13.5676 72.3594 13.5603C72.3592 13.553 72.3605 13.5457 72.3632 13.5389C72.3659 13.5321 72.3698 13.5259 72.3749 13.5207C72.3799 13.5154 72.386 13.5112 72.3926 13.5083C73.9678 12.788 74.9886 12.6551 75.51 13.1005C76.2448 13.7255 75.8224 15.3367 75.4484 16.7595C75.3362 17.1806 75.2284 17.5796 75.1668 17.9186C74.9688 18.9647 75.1228 19.7537 75.6244 20.2656C76.5594 21.2186 78.4162 20.895 78.436 20.8906C78.4432 20.8894 78.4506 20.8897 78.4577 20.8914C78.4648 20.8931 78.4716 20.8962 78.4775 20.9006C78.4834 20.9049 78.4884 20.9103 78.4923 20.9166C78.4961 20.9229 78.4987 20.9299 78.4998 20.9371C78.501 20.9444 78.5007 20.9519 78.499 20.959C78.4973 20.9662 78.4942 20.973 78.4899 20.9789C78.4856 20.9849 78.4802 20.9899 78.474 20.9938C78.4678 20.9977 78.4608 21.0003 78.4536 21.0014C78.1943 21.0389 77.9324 21.0559 77.6704 21.0524Z"
            fill="#002D2B"
          />
          <path
            id="Vector_164"
            d="M72.2545 12.7063C72.3095 12.4249 71.6055 12.5423 71.5725 12.7928C71.5395 13.0432 71.4471 12.9036 71.1721 13.2692C70.8971 13.6349 70.8597 14.0649 71.7617 14.3486C72.3337 14.5281 72.4217 14.0848 72.7143 13.8632C72.8749 13.7435 73.0553 13.6815 73.1719 13.5906C73.2006 13.5681 73.222 13.5375 73.2333 13.5027C73.2446 13.4679 73.2453 13.4305 73.2354 13.3952C73.2255 13.36 73.2054 13.3286 73.1776 13.3049C73.1498 13.2813 73.1157 13.2666 73.0795 13.2626C72.7099 13.2316 72.1973 12.9834 72.2545 12.7063Z"
            fill="#BE9137"
          />
          <path
            id="Vector_165"
            d="M67.3999 18.2967C67.301 18.4885 67.1634 18.6573 66.996 18.7924C66.8285 18.9275 66.6349 19.0259 66.4275 19.0812C65.9105 19.1965 65.7147 18.5959 65.9105 18.0108C66.0865 17.4878 66.5705 16.7941 67.0633 16.9448C67.5561 17.0955 67.6793 17.7892 67.3999 18.2967Z"
            fill="#FF8B7B"
          />
          <path
            id="Vector_166"
            d="M76.8171 85.5844C78.5265 85.3273 78.5595 85.0414 79.4769 84.7179C80.2623 84.4386 80.4823 84.4785 81.1159 84.2635C81.6461 84.084 81.4657 83.5765 81.1159 83.0357C80.9421 82.7654 80.9157 82.5083 80.7243 82.0273L78.1261 82.4152C77.8753 83.1465 77.7851 84.0818 76.3023 84.6159C75.4201 84.935 76.1285 85.6864 76.8171 85.5844Z"
            fill="#002D2B"
          />
          <path
            id="Vector_167"
            d="M60.9689 85.5913C62.6695 85.5913 62.9951 85.4783 63.9521 85.445C64.7705 85.4162 64.9047 85.5913 65.7341 85.5913C66.2841 85.5913 66.3611 84.9663 66.1587 84.4987C66.0025 84.1352 65.9783 83.6986 65.8639 83.2266H63.5209C63.0985 83.9734 61.9457 84.5275 60.6609 84.6206C59.7369 84.6893 60.2847 85.5913 60.9689 85.5913Z"
            fill="#002D2B"
          />
          <path
            id="Vector_168"
            d="M62.954 38.9238C62.954 38.9238 62.6152 51.2417 63.3412 59.065C63.295 66.4074 63.5194 83.2219 63.5194 83.2219C63.5766 83.5965 65.5302 83.4436 65.8624 83.2219C65.8624 83.2219 69.5254 70.9728 67.8996 59.065C71.9674 46.6408 71.0896 38.9238 71.0896 38.9238H62.954Z"
            fill="#002D2B"
          />
          <path
            id="Vector_169"
            d="M65.3984 38.9227C65.3984 38.9227 69.3716 52.8251 70.982 59.6533C72.676 66.8228 77.7096 77.6625 78.1276 82.4141C78.5676 82.5891 79.9734 82.3941 80.7258 82.0262C80.7258 82.0262 80.0372 66.8738 75.9452 59.9702C76.8692 42.985 72.2932 38.916 72.2932 38.916L65.3984 38.9227Z"
            fill="#002D2B"
          />
          <path
            id="Vector_170"
            d="M77.7822 81.4641C77.8222 81.7897 77.8391 82.1178 77.8328 82.4459C77.8307 82.4742 77.8344 82.5027 77.8437 82.5295C77.853 82.5563 77.8678 82.5809 77.887 82.6017C77.9062 82.6226 77.9295 82.6391 77.9554 82.6505C77.9812 82.6618 78.0092 82.6676 78.0374 82.6675C79.0098 82.6675 80.354 82.2508 80.7786 82.1046C80.8181 82.0915 80.8525 82.0663 80.877 82.0325C80.9014 81.9987 80.9147 81.958 80.915 81.9162V80.9876C80.9147 80.9578 80.9081 80.9285 80.8956 80.9016C80.883 80.8747 80.8648 80.8509 80.8422 80.8317C80.8197 80.8125 80.7932 80.7985 80.7648 80.7906C80.7363 80.7827 80.7065 80.7811 80.6774 80.7859L77.9406 81.2491C77.8924 81.2588 77.8495 81.2861 77.8203 81.3258C77.791 81.3656 77.7774 81.4148 77.7822 81.4641Z"
            fill="#BE9137"
          />
          <path
            id="Vector_171"
            d="M63.2493 82.189C63.2493 82.4438 63.2273 82.8982 63.2031 83.2328C63.2025 83.2863 63.2211 83.3381 63.2555 83.3788C63.2899 83.4196 63.3377 83.4464 63.3901 83.4544C64.2508 83.5257 65.1162 83.516 65.9751 83.4256C66.0163 83.422 66.0552 83.4048 66.0858 83.3768C66.1164 83.3488 66.137 83.3114 66.1445 83.2705L66.3205 82.3552C66.3245 82.3254 66.3224 82.295 66.3144 82.2661C66.3064 82.2371 66.2926 82.2101 66.2738 82.1866C66.2551 82.1632 66.2319 82.1439 66.2055 82.1298C66.1791 82.1157 66.1501 82.1072 66.1203 82.1048L63.4495 81.9984C63.4238 81.9969 63.398 82.0007 63.3738 82.0096C63.3495 82.0185 63.3273 82.0323 63.3086 82.0501C63.2898 82.068 63.2749 82.0895 63.2647 82.1134C63.2545 82.1373 63.2493 82.163 63.2493 82.189Z"
            fill="#BE9137"
          />
          <path
            id="Vector_172"
            d="M62.4264 27.8522C62.3744 29.2862 62.3949 30.722 62.488 32.1539C62.5606 34.0222 62.6882 35.8595 62.9544 38.9223H72.289C72.102 36.2739 72.4078 33.2554 74.0842 27.2959C74.1832 26.9842 74.2118 26.6542 74.1678 26.3299C74.1238 26.0057 74.0083 25.6955 73.8298 25.422C73.6514 25.1485 73.4145 24.9187 73.1364 24.7494C72.8584 24.5802 72.5463 24.4757 72.223 24.4436C71.8534 24.406 71.4596 24.3749 71.057 24.355C69.6464 24.2541 68.2304 24.2541 66.8198 24.355C66.3578 24.3971 65.8804 24.4569 65.425 24.5212C64.62 24.6416 63.8813 25.0394 63.3345 25.6467C62.7878 26.254 62.4669 27.0334 62.4264 27.8522Z"
            fill="#002D2B"
          />
          <path
            id="Vector_173"
            d="M72.2438 37.7598C70.376 38.2407 67.5336 38.3781 62.8674 37.8772C62.9796 40.3705 61.8906 57.1363 61.8906 57.1363C61.8906 57.1363 71.3506 59.2306 76.6988 56.3872C77.0442 43.717 72.2438 37.7598 72.2438 37.7598Z"
            fill="#BE9137"
          />
          <path
            id="Vector_174"
            opacity="0.2"
            d="M68.6719 47.8699C68.6719 47.8699 71.2393 45.4609 72.5065 43.8164C72.4053 45.5406 70.8763 47.0876 68.6719 47.8699Z"
            fill="black"
          />
          <path
            id="Vector_175"
            d="M54.5107 27.8406L55.1311 28.1575C55.2474 28.2157 55.3481 28.3013 55.4247 28.407C55.5013 28.5127 55.5515 28.6354 55.5711 28.7647L55.7537 29.9837C55.7916 30.2246 55.7715 30.4712 55.6949 30.7027C55.6183 30.9341 55.4876 31.1436 55.3137 31.3134L55.0937 31.535C54.0289 31.0785 53.2105 29.7953 53.2105 29.7953L52.9377 29.4562C52.8792 29.3881 52.8347 29.309 52.8069 29.2235C52.7791 29.1379 52.7684 29.0477 52.7756 28.9579C52.7828 28.8682 52.8076 28.7808 52.8487 28.7008C52.8897 28.6208 52.9461 28.5499 53.0147 28.4921L53.6747 27.9381C53.7899 27.8424 53.9301 27.7822 54.0784 27.7649C54.2267 27.7476 54.3768 27.7739 54.5107 27.8406Z"
            fill="#FF8B7B"
          />
          <path
            id="Vector_176"
            d="M65.6534 28.0616C65.5852 28.2522 65.5346 28.3851 65.4752 28.5381C65.4158 28.691 65.352 28.8284 65.2904 28.9813C65.1672 29.2694 65.0308 29.5465 64.8922 29.8235C64.6119 30.3778 64.302 30.9164 63.9638 31.4369C63.7966 31.6984 63.6184 31.9555 63.438 32.2104C63.2576 32.4652 63.064 32.7134 62.8594 32.955C62.4577 33.4501 62.0226 33.9166 61.557 34.3512L61.4954 34.4111C61.4396 34.4646 61.3784 34.5121 61.3128 34.5529C61.0435 34.7216 60.7449 34.8374 60.4328 34.8942C60.1731 34.9423 59.9079 34.9528 59.6452 34.9253C59.2455 34.877 58.8553 34.7679 58.488 34.6017C57.9167 34.3364 57.382 33.9974 56.8974 33.5933C56.4566 33.2305 56.0405 32.8381 55.6522 32.4187C55.2684 32.0089 54.9105 31.5751 54.5808 31.12C54.4667 30.9623 54.411 30.7693 54.4236 30.5746C54.4362 30.3799 54.5163 30.1958 54.6498 30.0544C54.7833 29.913 54.9619 29.8234 55.1543 29.801C55.3468 29.7786 55.5409 29.825 55.7028 29.9321L55.7402 29.9565C56.1494 30.2246 56.5828 30.5172 57.0052 30.7787C57.4276 31.0402 57.8544 31.2951 58.2724 31.51C58.6468 31.7135 59.0422 31.8748 59.4516 31.991C59.5703 32.0284 59.6955 32.0404 59.819 32.0264C59.8476 32.0264 59.8454 32.0043 59.819 32.0043C59.7668 32.0059 59.7156 32.0195 59.6694 32.0441L59.4274 32.248C59.7486 31.858 60.061 31.4591 60.3492 31.0313C60.6374 30.6036 60.9168 30.1648 61.1742 29.7016C61.4316 29.2384 61.6824 28.7818 61.9134 28.3076C62.03 28.0727 62.1334 27.8333 62.25 27.5962L62.4062 27.2416L62.547 26.9202L62.5844 26.836C62.7576 26.444 63.0746 26.1346 63.469 25.9726C63.8635 25.8106 64.3048 25.8086 64.7007 25.9669C65.0966 26.1252 65.4164 26.4317 65.5932 26.8221C65.7699 27.2125 65.7899 27.6566 65.649 28.0616H65.6534Z"
            fill="#FF8B7B"
          />
          <path
            id="Vector_177"
            d="M65.688 24.4863C64.1634 24.6858 62.6806 25.6388 61.3672 28.6839C61.904 29.8452 64.5176 31.0043 64.5176 31.0043C64.5176 31.0043 67.5316 27.2588 65.688 24.4863Z"
            fill="#002D2B"
          />
        </g>
      </g>
    </svg>
  );
};
