export const Instagram = ({ style }: { style?: {} }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={style}
      d="M5.99957 4.43765C5.13941 4.43765 4.43746 5.13961 4.43746 5.99976C4.43746 6.85992 5.13941 7.56187 5.99957 7.56187C6.85972 7.56187 7.56168 6.85992 7.56168 5.99976C7.56168 5.13961 6.85972 4.43765 5.99957 4.43765ZM10.6847 5.99976C10.6847 5.35289 10.6906 4.71187 10.6543 4.06617C10.6179 3.31617 10.4468 2.65054 9.8984 2.10211C9.34879 1.5525 8.68433 1.38258 7.93433 1.34625C7.28746 1.30992 6.64644 1.31578 6.00074 1.31578C5.35386 1.31578 4.71285 1.30992 4.06715 1.34625C3.31715 1.38258 2.65152 1.55367 2.10308 2.10211C1.55347 2.65172 1.38355 3.31617 1.34722 4.06617C1.3109 4.71304 1.31675 5.35406 1.31675 5.99976C1.31675 6.64547 1.3109 7.28765 1.34722 7.93336C1.38355 8.68336 1.55465 9.34898 2.10308 9.89742C2.65269 10.447 3.31715 10.6169 4.06715 10.6533C4.71402 10.6896 5.35504 10.6837 6.00074 10.6837C6.64761 10.6837 7.28863 10.6896 7.93433 10.6533C8.68433 10.6169 9.34996 10.4459 9.8984 9.89742C10.448 9.34781 10.6179 8.68336 10.6543 7.93336C10.6918 7.28765 10.6847 6.64664 10.6847 5.99976V5.99976ZM5.99957 8.40328C4.66949 8.40328 3.59605 7.32984 3.59605 5.99976C3.59605 4.66968 4.66949 3.59625 5.99957 3.59625C7.32965 3.59625 8.40308 4.66968 8.40308 5.99976C8.40308 7.32984 7.32965 8.40328 5.99957 8.40328ZM8.50152 4.05914C8.19097 4.05914 7.94019 3.80836 7.94019 3.49781C7.94019 3.18726 8.19097 2.93648 8.50152 2.93648C8.81207 2.93648 9.06285 3.18726 9.06285 3.49781C9.06294 3.57155 9.04849 3.64458 9.02031 3.71273C8.99213 3.78088 8.95079 3.84279 8.89865 3.89493C8.8465 3.94708 8.78459 3.98842 8.71644 4.0166C8.6483 4.04477 8.57526 4.05923 8.50152 4.05914V4.05914Z"
      fill="#002D2B"
    />
  </svg>
);
