const date = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="footer__wrapper_company">
          &copy; {date} DO ME A SOLID INC. All Rights Reserved
        </div>

        <div className="footer__wrapper_links">
          <a href="https://www.domeasolid.co/terms">Terms</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
