export const CloseSquare = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Close Square">
        <path
          id="Close Square_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.67 0H14.34C17.73 0 20 2.38 20 5.92V14.091C20 17.621 17.73 20 14.34 20H5.67C2.28 20 0 17.621 0 14.091V5.92C0 2.38 2.28 0 5.67 0ZM13.01 13C13.35 12.661 13.35 12.111 13.01 11.771L11.23 9.991L13.01 8.21C13.35 7.871 13.35 7.311 13.01 6.971C12.67 6.63 12.12 6.63 11.77 6.971L10 8.75L8.22 6.971C7.87 6.63 7.32 6.63 6.98 6.971C6.64 7.311 6.64 7.871 6.98 8.21L8.76 9.991L6.98 11.761C6.64 12.111 6.64 12.661 6.98 13C7.15 13.17 7.38 13.261 7.6 13.261C7.83 13.261 8.05 13.17 8.22 13L10 11.231L11.78 13C11.95 13.181 12.17 13.261 12.39 13.261C12.62 13.261 12.84 13.17 13.01 13Z"
          fill="#002D2B"
        />
      </g>
    </svg>
  );
};
