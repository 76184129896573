import Button from "../button";
import SectionHeader from "../section-header";
import { warning } from "../../assets/img";
import { success } from "../../assets/img";

type Props = {
  close: () => void;
  message?: string;
  type: string;
  title?: string;
  btnText: string;
  headText: string;
};

const PromptModal = ({
  close,
  type,
  message,
  title,
  btnText,
  headText,
}: Props) => {
  return (
    <div className="modal_container">
      <div className="modal_content size_sm prompt_modal">
        <SectionHeader
          headText={headText}
          icon="close-square"
          close={close}
          alignText="middle"
        />
        <div className="body">
          <p
            style={{ marginTop: "0px", marginBottom: "10px", fontSize: "20px" }}
          >
            {title}
          </p>
          <div className="image-container">
            <img
              src={type === "success" ? success : warning}
              style={{ width: "70px", marginBottom: "10px" }}
              alt="Warning"
            />
          </div>
          <h4>{message}</h4>
          <Button type="button" text={btnText} onClick={close} />
        </div>
      </div>
    </div>
  );
};

export default PromptModal;
