const regexWhitespace = /[\p{Z}\p{S}\p{P}\p{C}]+/u;
const regexNormalize = /[\u0300-\u036f]/g;

const regex = (text: string) => {
  return new RegExp(text, "g");
};

const regexA = regex("[àáâãäå]");
const regexE = regex("[èéêë]");
const regexI = regex("[ìíîï]");
const regexO = regex("[òóôõöő]");
const regexU = regex("[ùúûüű]");
const regexY = regex("[ýŷÿ]");
const regexN = regex("ñ");
const regexC = regex("[çc]");
const regexS = regex("ß");
const regexAnd = regex(" & ");
const regexAE = regex("ae");
const regexOE = regex("oe");
const regexSH = regex("sh");
const regexTH = regex("th");
const regexPH = regex("ph");
const regexPF = regex("pf");

const pairsSimple = [
  regexA,
  "a",
  regexE,
  "e",
  regexI,
  "i",
  regexO,
  "o",
  regexU,
  "u",
  regexY,
  "y",
  regexN,
  "n",
  regexC,
  "k",
  regexS,
  "s",
  regexAnd,
  " and ",
];

const pairsAdvanced = [
  regexAE,
  "a",
  regexOE,
  "o",
  regexSH,
  "s",
  regexTH,
  "t",
  regexPH,
  "f",
  regexPF,
  "f",
  regex("(?![aeo])h(?![aeo])"),
  "",
  regex("(?!^[aeo])h(?!^[aeo])"),
  "",
];

const prefixExtra = "(?!\\b)";
const regexVowelExtra = regex(prefixExtra + "[aeo]");

const regexStrip = /[^a-z0-9]+/;
const soundex: { [key: string]: string } = {
  b: "p",
  v: "f",
  w: "f",
  z: "s",
  x: "s",
  ß: "s",
  d: "t",
  n: "m",
  c: "k",
  g: "k",
  j: "k",
  q: "k",
  i: "e",
  y: "e",
  u: "o",
};

const pipeline = (
  text: string,
  normalize: Array<string | RegExp>,
  _collapse: boolean
) => {
  if (normalize) {
    text = replace(text, normalize);
  }

  if (_collapse && text.length > 1) {
    text = collapse(text);
  }

  const words = text.split(regexWhitespace);
  return words;
};

const normalize = (text: string) => {
  if (text.normalize) {
    text = text.normalize("NFD").replace(regexNormalize, "");
  }

  return text;
};

const replace = (text: string, regexp: Array<string | RegExp>): string => {
  for (let i = 0, len = regexp.length; i < len; i += 2) {
    text = text.replace(regexp[i], String(regexp[i + 1]));

    if (!text) {
      break;
    }
  }

  return text;
};

const collapse = (text: string) => {
  let final = "";
  let prev = "";

  for (let i = 0, len = text.length, char; i < len; i++) {
    if ((char = text[i]) !== prev) {
      final += prev = char;
    }
  }

  return final;
};

const encodeSimple = (text: string) => {
  text = "" + text;

  return pipeline(normalize(text).toLowerCase(), pairsSimple, false);
};

const encodeBalance = (text: string) => {
  text = encodeSimple(text).join(" ");

  const result = [];

  if (text) {
    const words = text.split(regexStrip);
    const length = words.length;

    for (let x = 0, tmp, count = 0; x < length; x++) {
      if ((text = words[x])) {
        tmp = text[0];
        let code = soundex[tmp] || tmp; // str[0];
        let previous = code; // soundex[code] || code;

        for (let i = 1; i < text.length; i++) {
          tmp = text[i];
          const current = soundex[tmp] || tmp;

          if (current && current !== previous) {
            code += current;
            previous = current;
          }
        }

        result[count++] = code; // (code + "0000").substring(0, 4);
      }
    }
  }

  return result;
};

const encodeAdvanced = (text: string) => {
  text = encodeBalance(text).join(" ");

  if (text.length > 2) {
    text = replace(text, pairsAdvanced);
  }
  return text;
};

const encode = (text: string) => {
  if (text) {
    text = encodeAdvanced(text);

    if (text.length > 1) {
      text = text.replace(regexVowelExtra, "");
    }

    if (text.length > 1) {
      text = collapse(text);
    }

    if (text) {
      const words = text.split(" ");
      return words;
    }
  }

  return [];
};

export const getEncodedString = (text: string): string => {
  const encoded: string[] = encode(text);
  const sortedEncoded: string[] = encoded.sort();
  return sortedEncoded.join(" ");
};
