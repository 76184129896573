export const Bulb = () => {
  return (
    <svg
      fill="#ffffff"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="800px"
      height="800px"
      viewBox="0 0 190.915 190.915"
    >
      <g>
        <g>
          <path
            d="M133.014,71.58C127.417,51.5,103.271,45.997,85.279,48.5c-16.906,2.352-34.789,12.786-37.24,31.377
			c-1.086,8.236-1.071,17.805,4.507,24.637c7.741,9.482,20.967,14.887,22.218,28.174c-0.582,0.025-1.014,0.352-1.219,0.838
			c-6.773,1.736-7.582,10.027-2.126,15.35c-3.31,3.336-5.345,7.862-1.186,11.729c0.448,0.418,0.974,0.746,1.483,1.095
			c-8.568,8.541,0.94,13.448,10.285,15.522c0.722,6.873,6.175,11.375,12.975,11.734c6.363,0.337,13.672-4.875,14.33-11.48
			c2.708-0.684,5.145-1.752,6.843-3.561c2.943-3.136,4.126-11.01,0.94-14.705c4.374-3.311,3.605-8.811-0.439-11.791
			c2.52-1.654,3.602-3.84,1.166-6.646c-2.101-2.42-5.392-3.768-8.716-4.549c0.003-0.113,0.035-0.213,0.02-0.332
			c-1.999-14.67,13.09-26.697,20.625-37.658C135.446,89.937,135.622,80.937,133.014,71.58z M95.859,185.604
			c-5.608,0.475-9.617-3.164-11.583-7.934c2.672,0.469,5.213,0.717,7.158,0.732c3.722,0.031,8.986,0.349,13.911-0.208
			C103.438,182.139,101.234,185.151,95.859,185.604z M91.434,174.829c-6.888-0.059-25.195-1.589-17.505-11.881
			c5.695,2.617,13.489,2.5,19.044,2.344c6.674-0.188,14.701-0.949,20.958-4.148C123.838,174.301,98.649,174.889,91.434,174.829z
			 M113.2,157.426c-0.018,0.008-0.032,0.019-0.05,0.027c-0.353,0.197-0.667,0.393-1.091,0.598
			c-5.43,2.629-12.039,3.305-17.967,3.662c-5.9,0.355-12.04,0.094-17.708-1.688c-6.105-1.916-5.481-5.598-2.679-9.573
			c2.627,1.269,5.834,1.392,8.653,1.581c6.38,0.427,12.849,0.098,19.216-0.373c1.989-0.146,7.447-0.959,11.767-2.619
			C116.567,152.942,117.531,154.971,113.2,157.426z M113.868,142.276c3.674,2.097-8.319,5.164-9.5,5.289
			c-6.295,0.676-12.668,0.851-18.994,0.746c-2.853-0.047-5.73-0.207-8.53-0.773c-6.61-1.339-7.488-10.389,0.265-10.822
			c0.393-0.023,0.692-0.16,0.948-0.338c7.982,1.301,16.169,1.84,24.181,2.654C106.279,139.442,110.308,140.244,113.868,142.276z
			 M93.418,134.387c-1.437-0.133-2.873-0.275-4.31-0.408c0.005-0.022,0.005-0.051,0.01-0.073c0.049-0.155,0.072-0.31,0.063-0.468
			c0.773-4.687-1.115-12.223-2.678-16.594c-1.679-4.699-4.259-9.111-6.624-13.484c-0.577-1.065-1.108-2.33-1.648-3.641
			c3.949-5.264,11.22-6.739,19.272-7.89c0.282-0.04,0.59-0.082,0.914-0.126C93.817,105.251,93.223,120.213,93.418,134.387z
			 M105.354,135.549c-0.485-0.064-0.969-0.125-1.431-0.17c-2.395-0.242-4.793-0.455-7.188-0.679
			c0.43-13.776,0.976-27.694,4.938-40.996c0.114-0.383,0.469-1.348,0.979-2.657c4.937-0.934,10.573-2.84,10.458-7.775
			c-0.037-1.611-2.001-1.989-2.865-0.776c-1.491,2.093-3.563,3.417-5.895,4.372c2.561-6.106,6.197-13.938,7.548-13.66
			c1.691,0.349,2.49-1.722,1.138-2.699c-5.322-3.841-11.005,12.377-12.191,14.992c-0.409,0.904-0.731,1.842-1.1,2.762
			c-4.664,0.989-9.759,1.26-13.867,2.506c-3.869,1.174-6.877,3.154-9.027,5.723c-0.86-1.889-1.829-3.653-3.09-4.845
			c-0.793-0.75-1.9-0.347-2.265,0.598c-0.996,2.572,1.13,5.546,2.261,7.806c0.152,0.303,0.323,0.598,0.477,0.901
			c-0.988,2.681-1.317,5.737-0.858,9.122c0.141,1.033,1.724,0.727,1.755-0.238c0.069-2.111,0.384-3.93,0.868-5.549
			c1.858,3.462,3.754,6.904,5.469,10.443c1.843,3.803,2.439,7.217,3.006,11.293c0.308,2.211,0.332,5.512,1.536,7.664
			c-2.718-0.252-5.436-0.5-8.151-0.77c0.093-11.148-7.126-16.83-15.471-23.749c-23.893-19.811-5.981-50.532,19.539-56.352
			c13.531-3.086,29.155-0.581,39.956,8.202c16.928,13.766,7.229,32.764-3.368,45.643
			C111.867,114.746,104.53,124.502,105.354,135.549z"
          />
          <path
            d="M50.55,117.022c-2.363,2.781-4.226,6.025-6.377,8.975c-2.068,2.835-5.274,5.266-6.906,8.344
			c-0.469,0.883,0.313,2.225,1.402,1.823c3.035-1.12,4.883-4.418,6.691-6.944c2.45-3.424,5.073-7.113,6.772-10.977
			C52.555,117.281,51.352,116.079,50.55,117.022z"
          />
          <path
            d="M31.052,84.38c-3.669-2.393-9.155-1.897-13.38-1.886c-5.617,0.015-11.481,0.595-16.942,1.939
			c-1.115,0.274-0.897,2.106,0.274,2.023c4.917-0.348,9.782-0.818,14.719-0.841c5.006-0.023,10.15,1.641,15.041,0.979
			C31.89,86.441,31.825,84.884,31.052,84.38z"
          />
          <path
            d="M55.193,41.729c-1.944-2.811-5.423-4.398-8.223-6.279c-3.574-2.4-7.159-4.931-10.976-6.928
			c-1.006-0.526-2.218,0.85-1.266,1.641c3.038,2.526,6.399,4.671,9.626,6.947c3.007,2.123,5.939,5.281,9.454,6.42
			C54.796,43.851,55.806,42.614,55.193,41.729z"
          />
          <path
            d="M90.563,3.232c-0.24-1.316-1.641-1.649-2.623-0.884c-1.633,1.273-1.137,4.174-1.141,5.953
			c-0.013,7.035-0.202,14.421,0.804,21.397c0.142,0.985,1.85,0.99,1.986,0c0.641-4.668,0.291-9.538,0.356-14.239
			c0.037-2.671,0.088-5.346,0.252-8.012c0.056-0.904,0.307-1.835,0.316-2.733c0.004-0.386-0.005-0.621-0.021-0.782
			C90.568,3.707,90.606,3.467,90.563,3.232z"
          />
          <path
            d="M146.323,15.494c-2.682-3.026-6.409,3.279-7.558,4.937c-1.742,2.515-3.357,5.118-5.049,7.667
			c-1.279,1.929-3.773,4.146-4.394,6.353c-0.172,0.611,0.09,1.466,0.71,1.744c0.046,0.021,0.092,0.042,0.138,0.062
			c0.633,0.284,1.336,0.29,1.856-0.239c0.532-0.542,0.796-1.198,1.535-1.515c0.566-0.244,0.532-0.775,0.252-1.173
			c0.537-0.559,1.087-1.106,1.433-1.573c1.317-1.777,2.544-3.623,3.787-5.452c1.207-1.776,2.419-3.549,3.697-5.276
			c0.527-0.713,1.098-1.389,1.696-2.043c0.327-0.356,1.57-1.09,0.889-1.063C146.593,17.872,147.153,16.43,146.323,15.494z"
          />
          <path
            d="M189.113,74.945c-7.936,0.268-15.862,0.462-23.788,0.96c-2.688,0.169-10.849-0.489-12.239,2.826
			c-0.348,0.83,0.903,1.465,1.468,0.855c0.166-0.179,4.854-0.104,5.394-0.139c3.306-0.212,6.618-0.339,9.928-0.455
			c6.394-0.223,12.841-0.491,19.238-0.316C191.564,78.743,191.466,74.683,189.113,74.945z"
          />
          <path
            d="M157.056,121.074c-1.018-1.302-3.066-1.81-4.496-2.542c-2.277-1.166-4.504-2.434-6.735-3.684
			c-2.378-1.328-7.142-5.189-10.026-4.248c-0.538,0.176-0.632,0.75-0.307,1.166c2.073,2.644,6.799,4.414,9.669,6.181
			c2.764,1.7,7.813,6.161,11.291,5.417C157.565,123.125,157.638,121.817,157.056,121.074z"
          />
        </g>
      </g>
    </svg>
  );
};
