export const PinQuestion = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" fill="white" fillOpacity="0.01" />
      <mask
        id="mask0_24684_24959"
        maskUnits="userSpaceOnUse"
        x="2"
        y="0"
        width="12"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15.5511L8.43849 15.1682C12.1258 11.9485 14 9.13719 14 6.66602C14 3.16646 11.2686 0.666016 8 0.666016C4.73137 0.666016 2 3.16646 2 6.66602C2 9.13719 3.87421 11.9485 7.56152 15.1682L8 15.5511ZM8.0002 13.775C4.87127 10.955 3.33353 8.56551 3.33353 6.66602C3.33353 3.93042 5.44294 1.99935 8.0002 1.99935C10.5575 1.99935 12.6669 3.93042 12.6669 6.66602C12.6669 8.56551 11.1291 10.955 8.0002 13.775ZM8.66688 8.66602H7.33355C7.33355 7.53159 7.74994 7.0458 8.70207 6.56973C9.24994 6.2958 9.33355 6.19825 9.33355 5.66602C9.33355 5.03696 8.85166 4.66602 8.00022 4.66602C7.26384 4.66602 6.66688 5.26297 6.66688 5.99935H5.33355C5.33355 4.52659 6.52746 3.33268 8.00022 3.33268C9.52311 3.33268 10.6669 4.2131 10.6669 5.66602C10.6669 6.80045 10.2505 7.28623 9.29836 7.7623C8.7505 8.03623 8.66688 8.13378 8.66688 8.66602ZM8.66731 9.99825C8.66731 10.3664 8.36874 10.6649 8.00043 10.6649C7.63212 10.6649 7.33354 10.3664 7.33354 9.99825C7.33354 9.63006 7.63212 9.33158 8.00043 9.33158C8.36874 9.33158 8.66731 9.63006 8.66731 9.99825Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_24684_24959)">
        <rect width="16" height="16" fill="#002D2B" />
      </g>
    </svg>
  );
};
