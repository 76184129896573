import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  orderBy,
  getDoc,
  DocumentReference,
  addDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { pickBy } from "lodash";
import prependHttp from "prepend-http";
import { getHostInfo } from "../utils/functions";
import { SocialMedia } from "../utils/constants";
const promoLinkCollection = collection(db, "promolink");
const influencerPromoMetricsCollection = collection(
  db,
  "influencerPromoMetrics"
);
const userCollection = collection(db, "user");

const isUrlHttp = (url: string) => {
  const REGEX_HTTP_PROTOCOL = /^https?:\/\//i;
  try {
    const { href } = new URL(url);
    return REGEX_HTTP_PROTOCOL.test(href) && href;
  } catch (err) {
    return false;
  }
};

const validateLinkData = (data: any) => {
  if (!data.url || !data.influencerId) {
    return false;
  } else {
    return true;
  }
};

const sanitizeData = (data: any) => {
  const sanitizedData: {
    [key: string]: string | boolean | DocumentReference;
  } = {
    url: data.url.trim() as string,
    title: data.title as string,
    category: data.category as string,
    promoCode: data.promoCode as string,
    promoLink: data.promoLink as string,
    codeTrackingLink: data.codeTrackingLink as string,
    addToExt: data.addToExt as boolean,
    isDeal: data.isDeal as boolean,
    deal: data.deal as string,
    expiredDateStr: data.expiredDateStr as string,
  };
  return pickBy(sanitizedData, (v: any) => v !== undefined);
};

export const getPromoLinks = async (data: any) => {
  const influencerRef = doc(db, "influencer", data.influencerId);
  const promoLinkQ = query(
    promoLinkCollection,
    where("influencer", "==", influencerRef),
    where("archived", "==", false),
    where("isEnabled", "==", true)
  );

  const promoLinkResult = await getDocs(promoLinkQ);

  if (promoLinkResult.empty) {
    return { links: {} };
  }
  return {
    links: Object.fromEntries(
      promoLinkResult.docs.map((docData) => {
        const jsonData = docData.data();
        delete jsonData.influencer;
        return [docData.id, jsonData];
      })
    ),
  };
};

export const addPromoLink = async (data: any) => {
  if (!validateLinkData(data)) {
    throw new Error("Failed to add a link");
  }
  const sanitizedData = sanitizeData(data);
  const influencerRef = doc(db, "influencer", data.influencerId);
  const influencerDoc = await getDoc(influencerRef);

  if (!influencerDoc.exists) {
    throw new Error("Influencer doesnt exist");
  }

  sanitizedData["influencer"] = influencerRef;
  const promoLinkQ = query(
    promoLinkCollection,
    where("influencer", "==", influencerRef)
  );

  const promoLinkResult = await getDocs(promoLinkQ);

  const length = promoLinkResult.docs.length;

  try {
    const url = data.url.trim();
    let fullUrl = url;

    if (!isUrlHttp(url)) {
      fullUrl = prependHttp(url, { https: false });
    }
    const urlObj = new URL(fullUrl);
    const { domain, topLevelDomains } = getHostInfo(urlObj.hostname);
    const doc = await addDoc(promoLinkCollection, {
      ...sanitizedData,
      domain: domain,
      topLevelDomains: topLevelDomains,
      archived: false,
      isEnabled: true,
      order: length,
    });

    const metricsMap: { [key: string]: any } = {};
    const landingPgInfoMap: { [key: string]: any } = {};
    const totalInfoMap: { [key: string]: number } = {};

    totalInfoMap["totalClicks"] = 0;
    landingPgInfoMap["timeline"] = {};
    landingPgInfoMap["total"] = totalInfoMap;

    metricsMap["category"] = sanitizedData.category;
    metricsMap["url"] = sanitizedData.url;
    metricsMap["dmsPage"] = landingPgInfoMap;
    metricsMap["promolink"] = doc;
    metricsMap["influencer"] = sanitizedData.influencer;
    metricsMap["archived"] = false;
    await addDoc(influencerPromoMetricsCollection, metricsMap);
    return { linkId: doc.id, url: data.url };
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error("Failed to add a link");
  }
};

export const updateLinkStatus = async (data: any) => {
  const promoLinkDoc = doc(db, "promolink", data.linkId);
  const enabled = data.isEnabled as boolean;
  await updateDoc(promoLinkDoc, { isEnabled: enabled });
  return { success: true };
};

export const saveLinkOrder = async (data: any) => {
  const { documentIds } = data;

  try {
    // Create a batch to perform multiple writes atomically
    const batch = writeBatch(db);

    // Save the documents in the custom order
    documentIds.forEach((docId: string, index: number) => {
      const documentRef = doc(db, "promolink", docId);
      batch.update(documentRef, { order: index });
    });

    // Commit the batch to save the changes
    await batch.commit();

    return { success: true };
  } catch (error) {
    throw new Error("An error occurred while saving the custom order.");
  }
};

export const fetchAllLinkMetrics = async (data: any) => {
  if (!data.startDate || !data.endDate || !data.influencerId) {
    throw new Error("Data wasn't provided");
  }
  const startDate = new Date(data.startDate);
  const startYear = startDate.getUTCFullYear();
  const startMonth = startDate.getUTCMonth();

  const endDate = new Date(data.endDate);
  const endYear = endDate.getUTCFullYear();
  const endMonth = endDate.getUTCMonth();

  const influencerRef = doc(db, "influencer", data.influencerId);
  const influencerDoc = await getDoc(influencerRef);
  const influencerData = influencerDoc.data();

  let influencerTotalImpressions = 0;

  if (influencerData) {
    influencerTotalImpressions = influencerData.viewCount;
  }
  const promoLinkQ = query(
    influencerPromoMetricsCollection,
    where("influencer", "==", influencerRef),
    where("archived", "==", false)
  );

  const promoMetricsDocs = await getDocs(promoLinkQ);
  const totalMetrics: { [key: string]: any } = {};
  const followersQ = query(
    userCollection,
    where("following", "array-contains", influencerRef)
  );

  const followersDocs = await getDocs(followersQ);

  let followersCount = 0;
  let followersWithExtCount = 0;

  if (!followersDocs.empty) {
    followersCount = followersDocs.docs.length;

    for (const followerDoc of followersDocs.docs) {
      const followerData = followerDoc.data();
      if (followerData.hasExtension) {
        followersWithExtCount += 1;
      }
    }
  }

  totalMetrics["count"] = {
    totalFollowers: followersCount,
    totalFollowersWithExt: followersWithExtCount,
  };
  totalMetrics["links"] = {};

  totalMetrics.count.totalImpressions = influencerTotalImpressions;
  if (promoMetricsDocs.empty) {
    totalMetrics.count.totalClicks = 0;
    return totalMetrics;
  }

  type referrerMap = { [key: string]: number };
  type NestedTimeDict = {
    [year: number]: { [month: number]: { [day: number]: referrerMap } };
  };
  const docMetrics: { [key: string]: any } = {};
  let totalCountOfAll = 0;
  let totalCountOfExtViews = 0;
  let totalCountOfExtClicks = 0;
  for (const doc of promoMetricsDocs.docs) {
    let totalPlatformCount = 0;
    const distinctPlatformCount = new Set();
    const referrerMetricsCountMap: { [key: string]: number } = {};
    const docData = doc.data();
    const timeline = docData.dmsPage.timeline as NestedTimeDict;

    for (const [year, yearDict] of Object.entries(timeline)) {
      const yearNum = parseInt(year);

      if (yearNum >= startYear && yearNum <= endYear) {
        for (const [month, monthDict] of Object.entries(yearDict)) {
          const monthNum = parseInt(month);
          const totalMonths = yearNum * 12 + monthNum + 1;
          const floorMonths = startYear * 12 + startMonth + 1;
          const ceilMonths = endYear * 12 + endMonth + 1;

          if (totalMonths >= floorMonths && totalMonths <= ceilMonths) {
            for (const [day, dailyReferrerDict] of Object.entries(monthDict)) {
              const dayNum = parseInt(day);
              const currentDate: Date = new Date(
                Date.UTC(yearNum, monthNum, dayNum)
              );

              if (currentDate >= startDate && currentDate <= endDate) {
                for (const socialMedia in SocialMedia) {
                  if (socialMedia in SocialMedia) {
                    const socialMediaDailyCount =
                      dailyReferrerDict[socialMedia] || 0;

                    // eslint-disable-next-line eqeqeq
                    if (socialMediaDailyCount != 0) {
                      distinctPlatformCount.add(socialMedia);
                    }
                    const currentSocialMediaCount =
                      referrerMetricsCountMap[socialMedia] || 0;
                    referrerMetricsCountMap[socialMedia] =
                      currentSocialMediaCount + socialMediaDailyCount;
                    totalPlatformCount += socialMediaDailyCount;
                  }
                }
              }
            }
          }
        }
      }
    }
    const totalClicksToDate = docData.dmsPage.total.totalClicks || 0;

    const totalExtViewsToDate = docData?.dmsExt?.total?.totalExtViews ?? 0;
    const totalExtClicksToDate = docData?.dmsExt?.total?.totalExtViews ?? 0;
    docMetrics[`${doc.id}`] = {
      individualReferrerMetrics: referrerMetricsCountMap,
      platformClicksForDate: totalPlatformCount,
      totalPlatformClicks: distinctPlatformCount.size,
      url: docData.url,
      category: docData.category,
    };
    totalCountOfAll += totalClicksToDate;
    totalCountOfExtViews += totalExtViewsToDate;
    totalCountOfExtClicks += totalExtClicksToDate;
  }
  totalMetrics.links = docMetrics;
  totalMetrics.count.totalClicks = totalCountOfAll;
  totalMetrics.count.totalExtViews = totalCountOfExtViews;
  totalMetrics.count.totalExtClicks = totalCountOfExtClicks;
  return totalMetrics;
};

export const updateLink = async (data: any) => {
  const updateData = {
    title: data.title,
    category: data.category,
    promoCode: data.promoCode,
    addToExt: data.addToExt,
    isDeal: data.isDeal,
    deal: data.deal,
    expiredDateStr: data.expiredDateStr,
  };
  const sanitizedData = pickBy(updateData, (v) => v !== undefined);
  const promoLinkDoc = doc(db, "promolink", data.linkId);
  await updateDoc(promoLinkDoc, sanitizedData);
  const response: { [key: string]: any } = {};
  response[`${data.promoLinkId}`] = "Successfully updated values";
  return response;
};

export const deleteLink = async (data: any) => {
  const promoLinkDoc = doc(db, "promolink", data.linkId);
  await updateDoc(promoLinkDoc, { archived: true });

  const influencerPromoMetricsCollection = collection(
    db,
    "influencerPromoMetrics"
  );

  const promoLinkQ = query(
    influencerPromoMetricsCollection,
    where("promolink", "==", promoLinkDoc)
  );
  const promoMetricsDocs = await getDocs(promoLinkQ);

  if (!promoMetricsDocs.empty) {
    const promoMetricsDoc = promoMetricsDocs.docs[0];
    await updateDoc(promoMetricsDoc.ref, { archived: true });
  }

  return {
    deleted: data.linkId,
  };
};
