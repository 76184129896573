export const Twitter = ({ style }: { style?: {} }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={style}
      d="M11.0811 2.82796C10.6994 2.99681 10.2946 3.1077 9.88015 3.15696C10.317 2.89568 10.644 2.48447 10.8001 1.99996C10.3901 2.24396 9.94065 2.41496 9.47215 2.50746C9.15745 2.17075 8.74033 1.94744 8.28563 1.87226C7.83092 1.79707 7.36412 1.87421 6.95778 2.09169C6.55144 2.30917 6.22834 2.65481 6.03871 3.07486C5.84907 3.49491 5.80352 3.96585 5.90915 4.41446C5.0777 4.37279 4.26431 4.15673 3.52179 3.7803C2.77927 3.40387 2.12421 2.87549 1.59915 2.22946C1.41329 2.54869 1.31563 2.91158 1.31615 3.28096C1.31615 4.00596 1.68515 4.64646 2.24615 5.02146C1.91415 5.01101 1.58946 4.92135 1.29915 4.75996V4.78596C1.29925 5.26881 1.46633 5.73677 1.77207 6.11049C2.07781 6.48422 2.50339 6.7407 2.97665 6.83646C2.66846 6.91998 2.3453 6.93229 2.03165 6.87246C2.16508 7.28808 2.42515 7.65156 2.77544 7.91202C3.12574 8.17247 3.54871 8.31686 3.98515 8.32496C3.55139 8.66563 3.05474 8.91745 2.52359 9.06605C1.99244 9.21464 1.43721 9.25709 0.889648 9.19096C1.8455 9.80568 2.9582 10.132 4.09465 10.131C7.94115 10.131 10.0446 6.94446 10.0446 4.18096C10.0446 4.09096 10.0421 3.99996 10.0381 3.91096C10.4476 3.61505 10.8009 3.24847 11.0816 2.82846L11.0811 2.82796Z"
      fill="#002D2B"
    />
  </svg>
);
