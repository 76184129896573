import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import * as EmailValidator from "email-validator";

import PageTitle from "../../components/page-title";
import PromptModal from "../../components/modal/prompt-modal";

import { logoSub } from "../../assets/img";
import { emailFormValidation, setCookie } from "../../utils/functions";

import Bowser from "bowser";
import Button from "../../components/button";
import FormInput from "../../components/form-input";
import { DMS_APP_CREATOR_REF } from "../../utils/constants";
import { getInfluencerByHandle, sendExtensionEmail } from "../../api";

type FormInputs = { email?: string };

const defaultImage =
  "https://png.pngtree.com/png-clipart/20210915/ourlarge/pngtree-avatar-placeholder-abstract-white-blue-green-png-image_3918476.jpg";

const SuccessPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<FormInputs>({
    mode: "onChange",
  });

  const params = useParams();
  const { handle } = params;

  const [creator, setCreator]: any = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [extensionError, setExtensionError] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const submitForm: SubmitHandler<FormInputs> = async (data: any) => {
    const validateEmailAddress = EmailValidator.validate(data.email);
    if (validateEmailAddress) {
      try {
        setIsSubmitting(true);
        await sendExtensionEmail(data.email, creator.handle);
        setShow(true);
        setIsSubmitting(false);
      } catch (error: any) {
        setIsSubmitting(false);
        setExtensionError(true);
      }
    } else
      setError("email", { message: "Please provide a valid email address" });
  };

  const browser = Bowser.getParser(window.navigator.userAgent);
  const os = browser.getOS();

  useEffect(() => {
    window.scroll(0, 0);
    if (!["Android", "macOS", "iOS"].includes(os.name as string)) {
      window.location.href = "https://www.domeasolid.co";
    }
  }, []);

  useEffect(() => {
    getCreatorInformation();
  }, []);

  async function getCreatorInformation() {
    if (handle) {
      setIsLoading(true);
      const influencer = await getInfluencerByHandle(handle);
      setCreator(influencer);
      setCookie(DMS_APP_CREATOR_REF, influencer.id, 1);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  return (
    <>
      <PageTitle title="Success" />
      <div className="success_page">
        <div className="page_frame">
          <div className="page_content">
            {isLoading ? (
              <div className="loading-container">
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <>
                {show && (
                  <>
                    <p className="title center">Success!</p>
                    <p className="sub_title center middle">
                      Check your email for the link and add the extension to
                      Chrome to start saving money.
                    </p>
                  </>
                )}
                <div className="image_container">
                  {creator ? (
                    <img
                      src={creator?.imageURL || defaultImage}
                      alt="Profile Avatar"
                      className="profile_picture"
                    />
                  ) : (
                    <img src={logoSub} alt="Logo" />
                  )}
                  {creator && <p className="mt-10 name">{creator?.name}</p>}
                </div>
                {!show && (
                  <>
                    <p className="sub_title center middle mt-30 fw-bold">
                      Safari extension coming soon...
                    </p>
                    <p className="sub_title center middle mt-30">
                      Get the desktop extension and we’ll meet you at checkout
                      with all your creator’s deals.
                    </p>
                    <div className="actions">
                      <form className="mt-30">
                        <label htmlFor="">Email</label>
                        <FormInput
                          type="email"
                          readOnly={isSubmitting}
                          placeholder="yourname@email.com"
                          errorMessage={errors?.email?.message}
                          inputRef={{
                            ...register("email", emailFormValidation(true)),
                          }}
                        />
                        <Button
                          text="Send me the link"
                          type="submit"
                          onClick={handleSubmit((data) => submitForm(data))}
                          disabled={!isValid || isSubmitting}
                          btnStyle={{ width: "100%" }}
                        />
                      </form>
                      <div className="terms">
                        <div className="text">
                          <p>
                            By clicking send, I agree to recieve news and offers
                            from Do Me a Solid and I agree to the{" "}
                            <a
                              onClick={() =>
                                window.open("https://domeasolid.co/terms")
                              }
                            >
                              Terms & Conditions
                            </a>{" "}
                            and{" "}
                            <a
                              onClick={() =>
                                window.open("https://domeasolid.co/policy")
                              }
                            >
                              Privacy Policy
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* <div className="background_blur">
                  <div className="ellipse_left"></div>
                  <div className="ellipse_right"></div>
                </div> */}
              </>
            )}
          </div>
          {extensionError && (
            <PromptModal
              type="warning"
              headText="Error!"
              message="We are unable to send the Extension link. Check if your email address is correct and try again."
              btnText="Try Again"
              close={() => setExtensionError(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SuccessPage;
