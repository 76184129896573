import { createSlice } from "@reduxjs/toolkit";
import mixpanel from "mixpanel-browser";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
  signOut,
  signInWithPhoneNumber,
  updatePassword,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { auth, functions } from "../../firebase";
import { fetchInfluencerInfo, updateInfluencerBio } from "../../db/influencer";
import {
  createUser,
  getUserType,
  fetchUserInfo,
  fetchUserRecommendation,
  updateUserBio,
  updateUserCategories,
  sendLoginEmail,
  doesUserExist,
} from "../../db/user";

import toast from "react-hot-toast";

import {
  useLocalStorage,
  getUserDetails,
  getUserTypeDetails,
  logout,
  getRecommendationFeed,
  getAllInfluencers,
} from "../../utils/functions";
import {
  MIXP_APP_REGISTER,
  MIXP_APP_LOGIN,
  DMS_APP_USER,
  DMS_APP_USER_TYPE,
  DMS_APP_CREATORS_FEED,
  DMS_APP_INFLUENCERS,
} from "../../utils/constants";
type Props = {
  user: {} | any;
  userType: string | any;
  creatorsFeed: [] | null;
};
export const initialState: Props = {
  user: getUserDetails(),
  userType: getUserTypeDetails(),
  creatorsFeed: getRecommendationFeed(),
};

// Slice
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, { payload }) => {
      delete payload.created;
      state.user = payload;
      useLocalStorage.set(DMS_APP_USER, payload);
    },
    setUserType: (state, { payload }) => {
      state.userType = payload;
      useLocalStorage.set(DMS_APP_USER_TYPE, payload);
    },
    logoutSuccess: (state) => {
      state.user = null;
      logout();
    },
    setCreatorsFeed: (state, { payload }) => {
      state.creatorsFeed = payload;
      useLocalStorage.set(DMS_APP_CREATORS_FEED, payload);
    },
  },
});
export default slice.reducer;

// Actions
const { loginSuccess, setUserType, logoutSuccess, setCreatorsFeed } =
  slice.actions;

export const authRegisterUserAction = (data: any) => async (dispatch: any) => {
  const email = data?.email;
  const password = data?.password;

  const res = await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = {
        userCredential: userCredential.user,
        displayName: userCredential.user.displayName,
        email: userCredential.user.email,
        emailVerified: userCredential.user.emailVerified,
        uid: userCredential.user.uid,
      };

      return user;
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const authLoginUserAction = (data: any) => async (dispatch: any) => {
  const email = data?.email;
  const phone = data?.phone;

  if (phone) {
    const res = await signInWithPhoneNumber(auth, phone, phone)
      .then((confirmationResult) => {
        // collect code from user
        const verificationCode = "000";
        return confirmationResult.confirm(verificationCode);
      })
      .then((userCredential) => {
        const user = {
          userCredential: userCredential.user,
        };
        console.log({ user });
        return user;
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  } else {
    const res = await signInWithEmailAndPassword(auth, email, "password")
      .then((userCredential) => {
        const user = {
          userCredential: userCredential.user,
          displayName: userCredential.user.displayName,
          email: userCredential.user.email,
          emailVerified: userCredential.user.emailVerified,
          uid: userCredential.user.uid,
        };

        return user;
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  }
};

export const confirmationCodeAction = (data: any) => async (dispatch: any) => {
  // @Todo; bring in the logic for confirmation code
};

export const authSendEmailVerificationAction =
  (data: any) => async (dispatch: any) => {
    const { credential } = data;

    const res = await sendEmailVerification(credential)
      .then(() => {
        auth.signOut();

        return { success: true };
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const authPasswordResetAction = (data: any) => async (dispatch: any) => {
  const { email } = data;

  const auth = getAuth();
  const res = await sendPasswordResetEmail(auth, email)
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const authVerifyPasswordResetCodeAction =
  (data: any) => async (dispatch: any) => {
    const { actionCode } = data;

    const auth = getAuth();
    const res = await verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        return { success: true, email };
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const authConfirmPasswordResetAction =
  (data: any) => async (dispatch: any) => {
    const { actionCode, newPassword } = data;

    const auth = getAuth();
    const res = await confirmPasswordReset(auth, actionCode, newPassword)
      .then(() => {
        return { success: true };
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const authVerifyEmailAction = (data: any) => async (dispatch: any) => {
  const { actionCode } = data;

  const auth = getAuth();
  const res = await applyActionCode(auth, actionCode)
    .then(() => {
      return { success: true };
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const getUserTypeAction = (data: any) => async (dispatch: any) => {
  const res = await getUserType({ uid: data })
    .then((result: any) => {
      dispatch(setUserType(result.type));
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const updateUserPasswordAction =
  (data: any) => async (dispatch: any) => {
    const user: any = auth.currentUser;
    const newPassword = data;

    const res = updatePassword(user, newPassword)
      .then(() => {
        toast.success("Password updated!");
        return { success: true };
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const updateUserThemeAction =
  (data: any) => async (dispatch: any, getState: any) => {
    const userState = getState()?.userSlice?.user;

    const func = httpsCallable(functions, "updateInfluencerTheme");
    const res = await func({
      influencerId: data?.influencerId,
      themeId: data?.theme?.id,
    })
      .then(() => {
        dispatch(loginSuccess({ ...userState, theme: data?.theme }));
        toast.success("Theme selection updated!");
        return { success: true };
      })
      .catch((error: any) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const logoutUser = () => (dispatch: any) => {
  signOut(auth)
    .then(() => {
      mixpanel.reset();
      dispatch(logoutSuccess());
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });
};

// Creator Function Starts Here

export const doesCreatorExist = (data: any) => async (dispatch: any) => {
  const func = httpsCallable(functions, "doesInfluencerExist");
  const res = await func({ uid: data?.uid })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const validateCreatorHandle = (data: any) => async (dispatch: any) => {
  const func = httpsCallable(functions, "validateInfluencerHandle");
  const res = await func({ handle: data })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const createCreatorAction = (data: any) => async (dispatch: any) => {
  const func = httpsCallable(functions, "createInfluencer");
  const res = await func(data)
    .then((result: any) => {
      mixpanel.alias(result?.data?.influencerId);
      mixpanel.track(MIXP_APP_REGISTER, {
        id: result?.data?.influencerId,
        uid: data?.uid,
        userType: "influencer",
      });
      mixpanel.people.set_once({
        name: data?.name,
        email: data?.email,
        userType: "influencer",
      });
      mixpanel.people.set("handle", data?.handle);

      return result.data;
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const fetchCreatorInfoAction = (data: any) => async (dispatch: any) => {
  const res = await fetchInfluencerInfo({ uid: data?.uid })
    .then((result) => {
      dispatch(loginSuccess(result));

      const responseData: any = result;

      if (data?.isLogin) {
        mixpanel.identify(responseData?.id);
        mixpanel.track(MIXP_APP_LOGIN, {
          id: responseData?.id,
          uid: responseData?.uid,
        });
        mixpanel.people.set_once({
          name: responseData?.name,
          email: responseData?.email,
          userType: "influencer",
        });
        mixpanel.people.set("handle", responseData?.handle);
      }

      return responseData;
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const updateCreatorNotificationsAction =
  (data: any) => async (dispatch: any) => {
    const func = httpsCallable(functions, "updateInfluencerNotifications");
    const res = await func(data)
      .then(() => {
        toast.success("Notification updated!");
        return { success: true };
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const updateInfluencerBioAction =
  (data: any) => async (dispatch: any) => {
    const res = await updateInfluencerBio(data)
      .then((result) => {
        toast.success("Update successful!");
        return result;
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const fetchInfluencerInfoAction =
  (data: any) => async (dispatch: any) => {
    const res = await fetchInfluencerInfo({ uid: data?.uid })
      .then((result) => {
        dispatch(loginSuccess(result));

        const responseData: any = result;

        if (data?.isLogin) {
          mixpanel.identify(responseData?.id);
          mixpanel.track(MIXP_APP_LOGIN, {
            id: responseData?.id,
            uid: responseData?.uid,
          });
          mixpanel.people.set_once({
            name: responseData?.name,
            email: responseData?.email,
            userType: "influencer",
          });
          mixpanel.people.set("handle", responseData?.handle);
        }

        return responseData;
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

// Shopper Function Starts Here

export const doesShopperExist = (data: any) => async (dispatch: any) => {
  try {
    const res = await doesUserExist(data);
    return res;
  } catch (error) {
    if (error instanceof Error) {
      toast.error(error.message);
    } else {
      toast.error("Database Error");
    }
  }
};

export const createShopperAction = (data: any) => async (dispatch: any) => {
  const res = await createUser(data)
    .then((result: any) => {
      mixpanel.alias(result?.userId);
      mixpanel.track(MIXP_APP_REGISTER, {
        id: result?.userId,
        uid: data?.uid,
        userType: "user",
      });
      mixpanel.people.set_once({
        name: data?.name,
        email: data?.email,
        userType: "user",
      });

      return result;
    })
    .catch((error) => {
      const errorMessage = error.message;
      console.log(errorMessage);
      toast.error(errorMessage);
    });

  return res;
};

export const fetchShopperInfoAction = (data: any) => async (dispatch: any) => {
  const res = await fetchUserInfo({ uid: data?.uid })
    .then((result) => {
      dispatch(loginSuccess(result));

      const responseData: any = result;

      if (data?.isLogin) {
        mixpanel.identify(responseData?.id);
        mixpanel.track(MIXP_APP_LOGIN, {
          id: responseData?.id,
          uid: responseData?.uid,
        });
        mixpanel.people.set_once({
          name: responseData?.name,
          email: responseData?.email,
          userType: "user",
        });

        dispatch(
          getCreatorYouMightLikeAction({
            uid: responseData?.id,
            following: responseData?.following,
          })
        );
      }

      return responseData;
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const updateShopperNotificationsAction =
  (data: any) => async (dispatch: any) => {
    const func = httpsCallable(functions, "updateUserNotifications");
    const res = await func(data)
      .then(() => {
        toast.success("Notification updated!");
        return { success: true };
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const updateUserCategoriesAction =
  (data: any) => async (dispatch: any) => {
    const res = await updateUserCategories(data)
      .then((result) => {
        return data;
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });

    return res;
  };

export const getCreatorYouMightLikeAction =
  (data: any) => async (dispatch: any) => {
    const res = await fetchUserRecommendation({
      userId: data?.uid,
      following: data?.following,
    })
      .then((result: any) => {
        dispatch(setCreatorsFeed(result));
        return result;
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast.error(errorMessage);
      });
    return res;
  };

export const updateUserBioAction = (data: any) => async (dispatch: any) => {
  const res = await updateUserBio(data)
    .then((result: any) => {
      toast.success("Update successful!");
      return result;
    })
    .catch((error) => {
      const errorMessage = error.message;
      toast.error(errorMessage);
    });

  return res;
};

export const sendUserSignInEmail = async (
  userEmail: string,
  actionLink: any
) => {
  await sendLoginEmail(userEmail, actionLink);
  return { success: true };
};
