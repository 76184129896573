export const Tiktok = ({ style }: { style?: {} }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={style}
      d="M9.66069 2.78109C9.58476 2.74185 9.51084 2.69883 9.43921 2.65219C9.23091 2.51448 9.03993 2.35222 8.87038 2.16891C8.44616 1.68352 8.28772 1.19109 8.22936 0.846328H8.23171C8.18296 0.560156 8.20311 0.375 8.20616 0.375H6.27397V7.84641C6.27397 7.94672 6.27397 8.04586 6.26975 8.14383C6.26975 8.15602 6.26858 8.16727 6.26788 8.18039C6.26788 8.18578 6.26788 8.19141 6.26671 8.19703V8.20125C6.24634 8.46932 6.16041 8.72828 6.01647 8.95535C5.87253 9.18242 5.67499 9.37063 5.44124 9.50344C5.19761 9.64204 4.92207 9.71474 4.64178 9.71438C3.74155 9.71438 3.01194 8.98031 3.01194 8.07375C3.01194 7.16719 3.74155 6.43313 4.64178 6.43313C4.81219 6.43297 4.98156 6.45978 5.14358 6.51258L5.14592 4.54523C4.65406 4.4817 4.15436 4.52079 3.67836 4.66004C3.20235 4.79929 2.76037 5.03568 2.3803 5.3543C2.04727 5.64366 1.76729 5.98891 1.55296 6.37453C1.47139 6.51516 1.16366 7.08024 1.12639 7.99734C1.10296 8.51789 1.25928 9.05719 1.33382 9.28008V9.28477C1.38069 9.41602 1.56233 9.86391 1.85835 10.2415C2.09704 10.5444 2.37905 10.8104 2.6953 11.0311V11.0264L2.69999 11.0311C3.63538 11.6667 4.67249 11.625 4.67249 11.625C4.85202 11.6177 5.45342 11.625 6.13639 11.3013C6.89389 10.9425 7.32514 10.4079 7.32514 10.4079C7.60065 10.0885 7.81972 9.72442 7.97296 9.33141C8.1478 8.8718 8.20616 8.32055 8.20616 8.10023V4.13648C8.2296 4.15055 8.54178 4.35703 8.54178 4.35703C8.54178 4.35703 8.99155 4.64531 9.69327 4.83305C10.1967 4.96664 10.875 4.99477 10.875 4.99477V3.07664C10.6373 3.10242 10.1548 3.02742 9.66069 2.78109Z"
      fill="#002D2B"
    />
  </svg>
);
